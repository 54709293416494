// Button.tsx - A versatile button component for different scenarios
import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  variant?: 'toggle' | 'delete' | 'add' | 'urlClient';
  isToggled?: boolean;
  disabled?: boolean; // Add the 'disabled' prop
}

function generateButtonStyles(variant: string | undefined, isToggled: boolean): string {
  const baseStyles = 'py-2 px-4 rounded-lg shadow focus:outline-none transition duration-300';
  const variantStyles = {
    toggle: isToggled ? 'bg-seaBlue hover:bg-blue-600 text-white' : 'bg-gray-400 hover:bg-gray-500 text-white',
    delete: 'bg-red-500 hover:bg-red-600 text-white',
    add: 'bg-seaBlue hover:bg-blue-600 text-white',
    urlClient: 'bg-seaBlue hover:bg-blue-600 text-white',
  };

  if (variant === 'toggle' || variant === 'delete' || variant === 'add' || variant === 'urlClient') {
    return `${baseStyles} ${variantStyles[variant]}`;
  }

  return baseStyles;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className = '', variant, isToggled = false }) => {
  const buttonStyles = generateButtonStyles(variant, isToggled);

  return (
    <button onClick={onClick} className={`${buttonStyles} ${className}`}>
      {children}
    </button>
  );
};

export default Button;
