import { useRouter } from 'next/router';
import { type MouseEvent, type FunctionComponent } from 'react';
import styles from '../../../styles/Navbar.module.css';

/**
 * Auth0LoginButton Component.
 * Triggers a redirection to the Auth0 Universal Login Page with a login hint when clicked.
 *
 * @component
 * @example
 * return (
 *   <Auth0LoginButton />
 * )
 */
const Auth0LoginButton: FunctionComponent = () => {
    const router = useRouter();

    /**
         * Event handler for the button click event.
         * Initiates a redirection to the Auth0 login page.
         *
         * @param {MouseEvent<HTMLAnchorElement>} e - The click event object.
         * @returns {Promise<void>} - A promise that resolves when the redirection is initiated.
         */
    const loginWithRedirect = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
        e.preventDefault();

        try {
            await router.push('/api/auth/login');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <button
            className={`mr-4 inline-flex items-center bg-[#fff] hover:bg-[#FFB238] text-black py-2 px-4 rounded-full ${styles.loginButton}`}
            type="button"
            onClick={loginWithRedirect}
        >
            <span className={styles.logInButtonText}>
                LOG IN
            </span>
        </button>
    );
};

export default Auth0LoginButton;
