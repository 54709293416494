import React, { type FormEvent } from 'react';
import { changeUserPassword } from '../../../services';

type ResetUserPasswordEmailFormProps = {
  email: string;
  callback: (success: boolean) => void;
};

const classes = {
  resetPassword: `
        rounded-full border border-transparent 
        bg-seaBlue px-8 py-3 text-[14px] text-white 
        shadow-sm hover:bg-sun hover:text-slate focus:outline-none 
        focus:ring-2 focus:ring-seaBlue focus:ring-offset-`,
};

export default function ResetUserPasswordEmailForm({ email, callback }: ResetUserPasswordEmailFormProps) {
  const handleResetPasswordEmailLink = async (event: FormEvent) => {
    event.preventDefault();

    const response = await changeUserPassword(email);

    if (response) {
      callback(true);
    }

    setTimeout(() => {
      callback(false);
    }, 5000);
  };

  return (
    <form onSubmit={handleResetPasswordEmailLink}>
      <button type="submit" className={classes.resetPassword}>
        Reset Password
      </button>
    </form>
  );
}
