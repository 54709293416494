import React, { type FC, type FormEvent, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { AppButton, Spinner } from '..';
import { TextInput } from '../utilities/TextInput';
import { captureException, captureMessage } from '@sentry/nextjs';
import { convertUrlToHttps, validateUrl } from '../../utils';

type CustomApplicationFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (app: { url: string; names: string }) => void;
};

export const CustomApplicationForm: FC<CustomApplicationFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [newAppName, setNewAppName] = useState('');
  const [newAppUrl, setNewAppUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [nameErrorVisible, setNameErrorVisible] = useState(false);
  const [urlErrorVisible, setUrlErrorVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleNewAppByUrlSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setNameError('');
    setUrlError('');
    setNameErrorVisible(false);
    setUrlErrorVisible(false);
    setSuccessMessage('');
    setErrorMessage('');

    try {
      if (!newAppName) {
        setNameError('Please provide an application name.');
        setNameErrorVisible(true);

        return;
      }

      if (!newAppUrl) {
        setUrlError('Please provide a URI.');
        setUrlErrorVisible(true);

        return;
      }

      const urlPattern = /^https:\/\/[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

      if (!urlPattern.test(newAppUrl)) {
        setUrlError('URI format should at least be in the format of https://example.com');
        setUrlErrorVisible(true);

        return;
      }

      const validator = await validateUrl(newAppUrl);

      if (!validator.isValid) {
        setUrlError('Invalid URI provided.');
        setUrlErrorVisible(true);

        return;
      }

      const correctedUrl = await convertUrlToHttps(newAppUrl);

      setIsLoading(true);
      onSubmit({ url: correctedUrl, names: newAppName });
      setSuccessMessage('Application added successfully.');
      setTimeout(() => {
        onClose();
        setNewAppUrl('');
        setNewAppName('');
      }, 1000);
    } catch (error) {
      captureMessage('Error adding app by URL');
      captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} as="div" className="fixed inset-0 z-10 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />

        <div className="relative bg-white rounded-lg max-w-md mx-auto p-6 space-y-4">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Create Custom Application
          </Dialog.Title>
          <p className="text-sm text-gray-600">
            This form allows you to add a custom application by providing its URL and a name. The URL should be valid
            and start with "https://". The name you choose will be permanent unless you delete and re-add the
            application.
          </p>
          <TextInput
            id="app-name"
            name="app-name"
            value={newAppName}
            onChange={setNewAppName}
            error={nameErrorVisible}
            errorMessage={nameError}
            placeholder="Application Name"
            className="rounded-md"
          />
          <TextInput
            id="app-url"
            name="app-url"
            value={newAppUrl}
            onChange={setNewAppUrl}
            error={urlErrorVisible}
            errorMessage={urlError}
            placeholder="https://google.com"
            className="rounded-md"
          />
          {isLoading && <Spinner />}
          {successMessage && <div className="text-sm text-green-600">{successMessage}</div>}
          {errorMessage && <div className="text-sm text-red-600">{errorMessage}</div>}
          <div className="flex justify-end space-x-2">
            <AppButton onClick={onClose} className="bg-white border border-gray-300 text-gray-700 hover:bg-gray-50">
              Cancel
            </AppButton>
            <AppButton
              onClick={handleNewAppByUrlSubmit}
              disabled={isLoading}
              className="bg-seaBlue text-white hover:bg-seaBlue-dark"
            >
              Add Application
            </AppButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CustomApplicationForm;
