import React from 'react';

import type { FC } from 'react';

export type CatalogLayoutControlProps = {
  value: string;
  onChange: (sort: { title: string; description: string; value: string }) => void;
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

export const itemsSortOptions = [
  { title: '⬇ A-Z', description: 'Sort items in ascending order.', value: 'name-asc' },
  { title: '⬆ Z-A', description: 'Sort items in descending order.', value: 'name-desc' },
];
const CatalogSortSelect: FC<CatalogLayoutControlProps> = ({ value = 'name-asc', onChange }) => {
  const handleSelect = (value: 'name-asc' | 'name-desc') => {
    const sort = itemsSortOptions.find(option => option.value === value);

    if (sort) {
      onChange(sort);
    }
  };

  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        onClick={() => handleSelect('name-asc')}
        className={classNames(
          value === 'name-asc' ? 'bg-gray-300 text-gray-900' : 'bg-white text-gray-900',
          'relative inline-flex items-center rounded-l-md px-3 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-sun hover:text-gray-900 focus:z-10 min-h-[40px] text-sm',
        )}
      >
        ⬆ A-Z
      </button>
      <button
        type="button"
        onClick={() => handleSelect('name-desc')}
        className={classNames(
          value === 'name-desc' ? 'bg-gray-300 text-gray-900' : 'bg-white text-gray-900',
          'relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-sun hover:text-gray-900 focus:z-10 min-h-[40px] text-sm',
        )}
      >
        ⬇ Z-A
      </button>
    </span>
  );
};

export default CatalogSortSelect;
