import { useState } from 'react';
import { ApplicationInfo, ConfirmationPopup, RolesDropDown, TrashIcon } from '..';
import type { FetchedUser } from '../../interfaces/User/UserAuth0ApiType';

type UserManageTableProps = {
  users: FetchedUser[];
  onChange: (user_id: string, role_id: string) => void;
  logged_user_id: string;
  handleDelete: (user_id: string) => void;
  onAssignBasicRole: (user_id: string) => void;
};

const styles = {
  th: `bg-sun py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate`,
  td: `whitespace-nowrap px-3 py-4 text-sm text-slate`,
};

export const UserManagementTable = ({
  users,
  onChange,
  logged_user_id,
  onAssignBasicRole,
  handleDelete,
}: UserManageTableProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deletingUserId, setDeletingUserId] = useState('');

  const showDeleteConfirmation = (userId: string) => {
    setDeletingUserId(userId);
    setShowConfirmation(true);
  };

  const confirmDelete = () => {
    handleDelete(deletingUserId);
    setShowConfirmation(false);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
  };

  function capitalizeFirstLetter(string: string) {
    if (!string || typeof string !== 'string' || string.length === 0) {
      console.error('Invalid input for capitalizeFirstLetter:', string);

      return '';
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className={`${styles.th}  sm:pl-6`}>
              Name
            </th>
            <th scope="col" className={styles.th}>
              Email
            </th>
            <th scope="col" className={styles.th}>
              Last Login
            </th>
            <th scope="col" className={`${styles.th}, text-center`}>
              Role
            </th>
            <th scope="col" className={styles.th}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-dashed divide-slate bg-white">
          {users &&
            users.map(user => {
              const dateString = user.last_login;
              const date = dateString ? new Date(dateString) : null;
              const isCurrentUser = user.user_id === logged_user_id;

              // eslint-disable-next-line react/no-unstable-nested-components
              const Role = () => {
                if (user.roles.length > 0) {
                  return (
                    <RolesDropDown
                      disabled={isCurrentUser}
                      roleId={user.roles[0].id}
                      onChange={onChange}
                      userId={user.user_id}
                    />
                  );
                }

                // Return the "Assign Basic Role" button for users with no roles
                return (
                  <div className="text-center">
                    <button
                      onClick={() => onAssignBasicRole(user.user_id)}
                      className="ml-2 p-1 text-blue-600 hover:text-blue-800"
                    >
                      Assign Basic Role
                    </button>
                  </div>
                );
              };

              return (
                <tr key={user.user_id}>
                  <td className={`${styles.td}, font-bold`}>
                    {user.name ? (
                      user.name.includes('@') ? (
                        capitalizeFirstLetter(user.name.split('@')[0])
                      ) : (
                        capitalizeFirstLetter(user.name)
                      )
                    ) : (
                      <span>No Name</span>
                    )}
                  </td>
                  <td className={styles.td}>{user.email}</td>
                  <td className={styles.td}>
                    {date
                      ? date.toLocaleString('default', {
                          month: 'short',
                          day: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          timeZoneName: 'short',
                        })
                      : 'No logins'}
                  </td>

                  {isCurrentUser ? (
                    <td className={styles.td}>
                      <div className="flex">
                        <ApplicationInfo text="You cannot change your role or delete your own account." />
                      </div>
                    </td>
                  ) : (
                    <td className={styles.td}>
                      <div className="mx-10">
                        <Role />
                      </div>
                    </td>
                  )}

                  <td className={styles.td}>
                    {!isCurrentUser && (
                      <TrashIcon
                          data-testid="delete-user"
                          disabled={user.email === logged_user_id}
                          handleDelete={() => showDeleteConfirmation(user.user_id)}
                        />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {showConfirmation && <ConfirmationPopup confirmDelete={confirmDelete} cancelDelete={cancelDelete} />}
    </div>
  );
};

export default UserManagementTable;
