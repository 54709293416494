import React, { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import type {
  CreateOrganizationApplicationDto,
  UnifiedApplication,
  UpdateOrganizationApplicationDto,
} from '../../interfaces/Client/Client';
import type { OrganizationData } from '../../interfaces/Organization/Organization';
import {
  createClientForOrganization,
  deleteSSOClient,
  getOrganizationByUserId,
  updateSSOClient,
  updateSSOClientCallbacks,
} from '../../services';
import { convertUrlToHttps, validateUrl } from '../../utils';
import Spinner from '../utilities/Spinner';
import { CopyButton } from '..';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

interface UpdateApplicationModalProps {
  application: UnifiedApplication;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  updateUnifiedApplications: (
    applicationId: string,
    applicationData: CreateOrganizationApplicationDto,
  ) => Promise<void>;
  setTriggerFetch: (triggerFetch: boolean) => void;
  organizationId: string;
  userSub: string;
  sso?: boolean;
  hasSSOCounterpart?: boolean;
}

const UpdateApplicationModal: React.FC<UpdateApplicationModalProps> = ({
  application,
  isOpen,
  setIsOpen,
  updateUnifiedApplications,
  organizationId,
  userSub,
  sso,
  setTriggerFetch,
  hasSSOCounterpart,
}) => {
  const [formData, setFormData] = useState({
    newUrl: '',
    callbackUrl: '',
    newCallbackUrl: '',
  });
  //TODO: Create function to reset form state
  const [message, setMessage] = useState({ text: '', type: '' });
  const [isUpdating, setIsUpdating] = useState(false);
  const [samlEndpoint, setSamlEndpoint] = useState('');
  const [signingKeyCert, setSigningKeyCert] = useState('');
  const [entityId, setEntityId] = useState('');
  const [isFullCertShown, setIsFullCertShown] = useState(false);
  const [isNewClientCreated, setIsNewClientCreated] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState(application?.callbacks?.[0]);
  const STEP_LIST = ['initial', 'configureSSO', 'updateUrl', 'reviewSSOConfig', 'successUrl'];
  const [currentStep, setCurrentStep] = useState(STEP_LIST[0]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (application?.customUrl?.includes('samlp')) {
      setCurrentStep(STEP_LIST[STEP_LIST.indexOf('reviewSSOConfig')]);
      setFormData({
        ...formData,
        callbackUrl: application.callbacks?.[0],
      });
      setMessage({ text: '', type: '' });
    } else {
      setCurrentStep(STEP_LIST[STEP_LIST.indexOf('initial')]);
      setMessage({ text: '', type: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application.customUrl, isOpen]);

  const resetForm = () => {
    setFormData({ newUrl: '', callbackUrl: '', newCallbackUrl: '' });
    setMessage({ text: '', type: '' });
    setIsUpdating(false);
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(STEP_LIST[STEP_LIST.indexOf('initial')]);
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSSOConfiguration = async () => {
    setIsUpdating(true);

    try {
      const organizationData: OrganizationData = await getOrganizationByUserId(userSub);

      if (organizationData && organizationData.organization) {
        const clientName = `${organizationData?.organization.name}-${application.name}`;

        const createResponse = await createClientForOrganization(clientName, true);

        if (
          !createResponse ||
          !createResponse.client_id ||
          !createResponse.signing_keys ||
          !createResponse.signing_keys[0]
        ) {
          throw new Error('createClientForOrganization did not return a valid response');
        }

        const signingKey = createResponse.signing_keys[0].cert as string;

        setSigningKeyCert(signingKey);

        const clientId = createResponse.client_id as string;

        const auth0IssuerBaseUrl = process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL as string;
        const updatedSamlEndpoint = `${auth0IssuerBaseUrl}/samlp/${clientId}`;

        setSamlEndpoint(updatedSamlEndpoint);

        let entityIdUrn = '';

        if (auth0IssuerBaseUrl !== undefined) {
          entityIdUrn = `urn:${auth0IssuerBaseUrl.split('//')[1]}`;
        } else {
          console.error('auth0IssuerBaseUrl is undefined');
        }

        setEntityId(entityIdUrn);

        try {
          await updateSSOClient(
            clientId,
            updatedSamlEndpoint,
            entityIdUrn,
            formData.callbackUrl,
            application.logo as string,
          );
          setMessage({ text: 'SSO configuration updated successfully.', type: 'success' });
          setCurrentStep(STEP_LIST[STEP_LIST.indexOf('reviewSSOConfig')]);
          setIsNewClientCreated(true);
        } catch (error) {
          console.error('Error during SSO configuration:', error);
          setMessage({ text: 'An error occurred while configuring SSO.', type: 'error' });

          // Delete the SSO client if the updateSSOClient call fails
          try {
            await deleteSSOClient(clientId);
          } catch (deleteError) {
            console.error('Error deleting SSO client:', deleteError);
          }
        }
      }
    } catch (error) {
      console.error('Error during SSO configuration:', error);
      setMessage({
        text: 'An error occurred while configuring SSO, or the SSO application already exist, please refresh page.',
        type: 'error',
      });
      resetForm();
    } finally {
      setIsUpdating(false);
    }
  };

  const handleUpdateUrl = async () => {
    if (formData.newUrl.trim() === '') {
      setMessage({ text: 'URL cannot be empty. Please enter a valid URL.', type: 'error' });

      return;
    }

    let isValid;
    let web;

    try {
      const result = await validateUrl(formData.newUrl);

      isValid = result.isValid;
      web = result.web;
    } catch (error) {
      console.error('Error during URL validation:', error);
      resetForm();
    }

    if (!isValid) {
      setMessage({ text: 'Invalid URL', type: 'error' });

      return;
    }

    let finalUrl = formData.newUrl;

    if (web) {
      finalUrl = await convertUrlToHttps(formData.newUrl);
    }

    const updateData: UpdateOrganizationApplicationDto = {
      organizationId,
      applicationId: application.applicationId as string,
      customUrl: finalUrl,
    };

    const originalUrl = application.customUrl;

    application.customUrl = finalUrl;

    setIsUpdating(true);

    try {
      await updateUnifiedApplications(application.applicationId as string, updateData);
      setMessage({
        text: `Application URL updated successfully to ${updateData.customUrl as string}.`,
        type: 'success',
      });
      setCurrentStep(STEP_LIST[STEP_LIST.indexOf('successUrl')]);
    } catch (error) {
      updateData.customUrl = originalUrl;
      setMessage({ text: 'An error occurred while updating the application URL.', type: 'error' });
      resetForm();
    } finally {
      setIsUpdating(false);
    }
  };

  const finalizeNewSSOSetup = async () => {
    setMessage({
      text: `SSO Application successfully created!`,
      type: 'success',
    });
    setTriggerFetch(true);
    setIsNewClientCreated(false);
  };

  const finalizeUpdateSSOSetup = async () => {
    setIsUpdating(true);

    if (formData.newCallbackUrl.trim() === '') {
      setIsUpdating(false);
      setMessage({ text: 'Callback URL cannot be empty. Please enter a valid URL.', type: 'error' });

      return;
    }

    let isValid;
    let web;

    try {
      const result = await validateUrl(formData.newCallbackUrl);

      isValid = result.isValid;
      web = result.web;
    } catch (error) {
      console.error('Error during URL validation:', error);
    }

    if (!isValid) {
      setMessage({ text: 'Invalid URL', type: 'error' });
      setIsUpdating(false);

      return;
    }

    let callbackUrlToUpdate = formData.newCallbackUrl;

    if (web) {
      callbackUrlToUpdate = await convertUrlToHttps(formData.newCallbackUrl);
    }

    if (callbackUrlToUpdate === formData.callbackUrl) {
      setMessage({
        text: `Callback Url has not been changed and will remain the same.`,
        type: 'info',
      });
    } else {
      const client_id = application.uuid;

      await updateSSOClientCallbacks(client_id, callbackUrlToUpdate);
      setCallbackUrl(callbackUrlToUpdate); // Optimistically update the callback URL
      setMessage({
        text: `Callback Url for SSO Application successfully updated!`,
        type: 'success',
      });
    }

    setIsUpdating(false);
  };

  useEffect(() => {
    if (message.text === `Callback Url for SSO Application successfully updated!`) {
      setTimeout(() => {
        setIsOpen(false);
      }, 2500);
    }
  }, [message, setIsOpen]);

  const renderContent = () => {
    switch (currentStep) {
      case 'initial':
        if (isUpdating) {
          return (
            <>
              <h1 className="mb-4 text-lg leading-6 font-semibold text-gray-900">Updating Application...</h1>
              <div className="mb-20 flex items-center justify-center">
                <Spinner />
              </div>
            </>
          );
        }

        return (
          <>
            <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h1 className="mb-4 text-lg leading-6 font-semibold text-gray-900">
                    Update Application or Configure SSO
                  </h1>
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Get Started
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Choose one of the options below to get started. You can update your application's access URL or
                      configure Single Sign-On (SSO) settings for a seamless user authentication experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse items-center justify-center">
              <button
                onClick={() => setCurrentStep('updateUrl')}
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-seaBlue text-base font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-seaBlue sm:ml-3 sm:w-auto sm:text-sm"
              >
                Update Application URL
              </button>
              {sso && !hasSSOCounterpart && (
                <button
                  onClick={() => setCurrentStep('configureSSO')}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sun text-base font-medium text-black hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sun sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Configure SSO
                </button>
              )}
              <button
                onClick={() => setIsOpen(false)}
                className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sun sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          </>
        );
      case 'updateUrl':
        return (
          <>
            <h3 className="mb-4 text-lg leading-6 font-semibold text-gray-900">Update Application or Configure SSO</h3>
            <div className="text-sm mb-4">
              Enter the new URL for your application. Ensure the URL starts with 'https://' to promote secure access. If
              your application requires specific paths or parameters, include those in the URL.
            </div>
            <div className="mt-4">
              <label htmlFor="url" className="block text-sm font-medium text-gray-700">
                New Application URL
              </label>
              <input
                id="url"
                type="text"
                value={formData.newUrl}
                onChange={e => {
                  setFormData({ ...formData, newUrl: e.target.value });
                }}
                className="mt-1 block w-full p-2.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder={application.customUrl ? `Current: ${application.customUrl}` : 'https://example.com'}
              />
            </div>
            <div className="flex justify-between items-center mt-4">
              <button
                className="w-auto h-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setCurrentStep('initial');
                  setFormData({
                    newUrl: '',
                    callbackUrl: '',
                    newCallbackUrl: '',
                  });
                  setMessage({ text: '', type: '' });
                }}
              >
                <div className="flex items-center">Back to Initial</div>
              </button>
              <button
                onClick={handleUpdateUrl}
                disabled={isUpdating}
                className={`w-auto h-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${isUpdating ? 'bg-gray-400' : 'bg-seaBlue hover:bg-blue-700'} rounded-md`}
              >
                <div className="flex items-center">{isUpdating ? 'Updating...' : 'Update URL'}</div>
              </button>
            </div>
            {message.type === 'error' && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-red-700">{message.text}</p>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      case 'configureSSO':
        return (
          <>
            <div className="text-sm mb-4">
              Setting up Single Sign-On (SSO) allows users to access multiple services with one set of credentials,
              enhancing both security and convenience. You'll need to provide the 'Callback URL', a crucial component in
              the SSO framework.
            </div>
            <div className="mt-4">
              <label htmlFor="callbackUrl" className="block text-sm font-medium text-gray-700">
                Callback URL
              </label>
              <input
                id="callbackUrl"
                type="text"
                value={formData.callbackUrl}
                onChange={e => {
                  setFormData({ ...formData, callbackUrl: e.target.value });
                }}
                className="mt-1 block w-full p-2.5 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder={`e.g. ${application?.ssoUrlExample ?? 'https://yourdomain.example.com/sso/saml'}`}
              />
              <p className="mt-1 text-xs text-gray-500">
                This URL is where the SSO provider sends the user after authentication. It must be secure and correctly
                configured in your SSO settings.{' '}
                <strong>Note: Callback Url format may vary based on your SSO provider.</strong>
              </p>
            </div>
            <div className="flex justify-between items-center my-6">
              {isUpdating ? (
                <Spinner />
              ) : (
                <>
                  <button
                    className="w-auto h-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setCurrentStep('initial');
                      setFormData({
                        newUrl: '',
                        callbackUrl: '',
                        newCallbackUrl: '',
                      });
                      setMessage({ text: '', type: '' });
                    }}
                  >
                    <div className="flex items-center">Back to Initial</div>
                  </button>
                  <button
                    onClick={handleSSOConfiguration}
                    disabled={isUpdating}
                    className={`w-auto h-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${isUpdating ? 'bg-gray-400' : 'bg-seaBlue hover:bg-blue-700'} rounded-md`}
                  >
                    {isUpdating ? 'Enabling SSO Configuration...' : 'Enable SSO'}
                  </button>
                </>
              )}
            </div>
            {message.type === 'error' && (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className="text-sm text-red-700">{message.text}</p>
                    <button
                      onClick={() => setTriggerFetch(true)}
                      className={`w-auto h-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-white ${isUpdating ? 'bg-gray-400' : 'bg-seaBlue hover:bg-blue-700'} rounded-md`}
                    >
                      Refresh Page
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      case 'reviewSSOConfig':
        return (
          <>
            <h3 className="text-lg leading-6 font-semibold text-gray-900">Review SSO Configuration</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Please review the configuration details below to ensure accuracy before finalizing your SSO setup.
              </p>
              {isNewClientCreated && (
                <div className="mt-4">
                  <p className="text-sm text-red-600">
                    Press "Finalize SSO Setup" after reviewing to complete the process and fully add your SSO configured
                    application.
                  </p>
                </div>
              )}
              <ul className="mt-4 list-disc list-inside text-sm text-gray-600">
                <li style={{ overflow: 'auto', maxHeight: '200px' }} className="flex justify-between items-center">
                  <div>
                    <strong>Entity ID:</strong>{' '}
                    {entityId !== ''
                      ? entityId
                      : `urn:${application?.customUrl?.split('//')[1]?.replace(/\.com.*$/, '.com')}`}
                  </div>
                  <CopyButton
                    text={
                      entityId !== ''
                        ? entityId
                        : `urn:${application?.customUrl?.split('//')[1]?.replace(/\.com.*$/, '.com')}`
                    }
                  />
                </li>
                <br />
                <li className="flex justify-between items-center">
                  <div>
                    <strong>Callback URL:</strong>
                    {callbackUrl || formData.callbackUrl || application?.callbacks?.[0]}
                  </div>
                  <CopyButton text={callbackUrl || formData.callbackUrl || application?.callbacks?.[0]} />
                </li>
                <br />
                <li style={{ overflow: 'auto', maxHeight: '200px' }} className="flex justify-between items-center">
                  <div>
                    <strong>SAML Endpoint:</strong>{' '}
                    {samlEndpoint !== '' ? samlEndpoint : (application?.customUrl as string)}
                  </div>
                  <CopyButton text={samlEndpoint !== '' ? samlEndpoint : (application?.customUrl as string)} />
                </li>
                <br />
                <li className="flex flex-col justify-between items-start overflow-auto max-h-200">
                  <div className="flex justify-between items-center w-full">
                    <strong>Signing Key Certificate:</strong>
                    <button onClick={() => setIsFullCertShown(!isFullCertShown)} className="text-blue-500 underline">
                      {isFullCertShown ? 'Show Less' : 'Show More'}
                    </button>
                  </div>
                  <div className="mt-2 w-full">
                    {isFullCertShown ? (
                      <span>{application.signing_keys?.[0]?.cert?.toString() ?? signingKeyCert}</span>
                    ) : application.signing_keys?.[0]?.cert || signingKeyCert ? (
                      <span>
                        {(application.signing_keys?.[0]?.cert?.toString() ?? signingKeyCert).substring(0, 155)}...
                      </span>
                    ) : (
                      <span>No signing key certificate available yet, please refresh page.</span>
                    )}
                  </div>
                  <CopyButton
                    text={application.signing_keys?.[0]?.cert?.toString() ?? signingKeyCert}
                    className="mt-2"
                  />
                </li>
              </ul>
            </div>
            <div className="mt-4">
              <p className="text-sm text-gray-500">
                After confirming, proceed to configure the SSO integration within your identity provider using these
                details. If you're using Auth0, this will involve logging into your Auth0 dashboard and setting up a new
                application with the provided Entity ID and Callback URL.
              </p>
            </div>
            {isUpdating ? (
              <div className="flex justify-center items-center h-12">
                <div className="w-4 h-4 mr-3">
                  <Spinner />
                </div>
              </div>
            ) : (
              message.text && (
                <div className={`rounded-md p-4 ${message.type === 'error' ? 'bg-red-50' : 'bg-green-50'}`}>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon
                        className={`h-5 w-5 ${message.type === 'error' ? 'text-red-400' : 'text-green-400'}`}
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className={`text-sm ${message.type === 'error' ? 'text-red-700' : 'text-green-700'}`}>
                        {message.text}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
            <div className="mt-4">
              <label htmlFor="newCallbackUrl" className="block text-sm font-semibold text-gray-700">
                Update Callback URL (Optional):
              </label>
              <span className="text-sm font-semibold text-center text-red-600">
                CAUTION: THIS SHOULD ONLY BE DONE IF YOU HAVE UPDATED AND CONFIRMED YOUR CONFIGURATION WITH YOUR SERVICE
                PROVIDER.
              </span>
              <input
                id="newCallbackUrl"
                type="text"
                value={formData.newCallbackUrl}
                onChange={e => setFormData({ ...formData, newCallbackUrl: e.target.value })}
                className="mt-2 block w-full p-2.0 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="https://yourdomain.zendesk.com/sso/saml"
              />
            </div>
            <div className="flex justify-center items-center space-x-4">
              {!isNewClientCreated && (
                <button
                  className="w-1/2 inline-flex justify-center px-4 py-2 text-sm font-medium text-black bg-gray-300 hover:bg-gray-300 rounded-md"
                  onClick={() => setIsOpen(false)}
                >
                  Close
                </button>
              )}
              <button
                className="w-1/2 inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-seaBlue hover:bg-blue-700 rounded-md"
                onClick={isNewClientCreated ? finalizeNewSSOSetup : finalizeUpdateSSOSetup}
                disabled={isUpdating}
              >
                Finalize SSO Setup
              </button>
            </div>
          </>
        );
      case 'successUrl':
        return (
          <>
            <h1 className="mb-4 text-lg leading-6 font-semibold text-gray-900">
              Successfully Updated Application URL!
            </h1>
            <button
              className="w-auto h-auto inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-300 text-base font-medium text-black hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                setCurrentStep('initial');
                setFormData({
                  newUrl: '',
                  callbackUrl: '',
                  newCallbackUrl: '',
                });
                setMessage({ text: '', type: '' });
              }}
            >
              <div className="flex items-center">Back to Initial</div>
            </button>
            <button
              className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-sun text-base font-medium text-black hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sun sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        open={isOpen}
        onClose={() => {
          if (!isNewClientCreated && !isUpdating) {
            setIsOpen(false);
            setTriggerFetch(true);
          }
        }}
      >
        {/* Overlay Background */}
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* Modal Content */}
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="content-container" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {renderContent()}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateApplicationModal;
