import React, { memo } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { ProfileDropdown } from '../..';
import logo from '../../../assets/cubeless.png';
import vertDash from '../../../assets/vertical-dash.png';
import { capitalizeFirstLetter } from '../../../utils';
import { useUserContext } from '../../../context/UserContext';
import cubelessLogo from 'public/favicon.ico';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const AdminNavbar = ({
  organizationName,
  userRole,
  logoUrl,
}: {
  organizationName: string;
  userRole: string;
  logoUrl: string;
}) => {
  const { user } = useUserContext();

  return (
    <div>
      <Disclosure as="nav" className="bg-white shadow-lg" style={{ zIndex: 100, position: 'relative' }}>
        {({ open }) => (
          <>
            <div className="mx-auto sm:px-6 lg:px-8">
              <div className="border-gray-700">
                <div className="flex items-center justify-between px-4 sm:px-0">
                  <div className="flex-1">
                    <div className="h-0 flex flex-shrink-0 items-center px-2">
                      <Image className="h-10 w-auto" src={logo} alt="Cubeless" />
                      <span className="px-[30px]">
                        <Image src={vertDash} alt="seperator" className="h-41 w-auto" />
                      </span>
                      <div className="md:text-md 2xl:text-md">
                        {logoUrl ? (
                          <Image
                            src={logoUrl || ''}
                            alt={capitalizeFirstLetter(organizationName || '')}
                            className="h-41 w-auto"
                            width={60}
                            height={60}
                          />
                        ) : (
                          <p className="text-lg font-semibold py-5 w-95 text-[#2B3D45] md:text-md 2xl:text-md">
                            {capitalizeFirstLetter(organizationName || '')}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center" />
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <div className="hidden md:block">
                        <div className="ml-4 flex items-baseline space-x-4">
                          {userRole === 'superAdmin' && (
                            <span className="block px-4 py-2 text-md font-medium">{publicRuntimeConfig.VERSION}</span>
                          )}
                          <div className="text-lg font-semibold py-5 w-95 text-[#2B3D45] md:text-md 2xl:text-md">
                            Hello, {user && capitalizeFirstLetter(user.nickname)}
                          </div>
                        </div>
                      </div>
                      <ProfileDropdown userRole={userRole} />
                    </div>
                  </div>
                  {/* Close Mobile Drop Down View Button */}
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>
            {/* Mobile Pop-up Navigation when screen is sm to xs */}
            <Disclosure.Panel className="md:hidden">
              <div className="pt-2 pb-3 space-y-1">{/* Mobile Menu Items Here */}</div>
              <div className="pt-4 pb-3 border-t border-gray-700">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    {user?.picture && user.picture !== '' ? (
                      <Image
                        className="h-10 w-10 rounded-full"
                        src={user.picture}
                        width={30}
                        height={30}
                        alt="Profile"
                      />
                    ) : (
                      <Image
                        className="h-10 w-10 rounded-full"
                        src={cubelessLogo}
                        width={30}
                        height={30}
                        alt="Cubeless Logo"
                      />
                    )}
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">{user?.name as string}</div>
                    <div className="text-sm font-medium leading-none text-gray-400">{user?.email as string}</div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
                  />
                </div>
                {/* User Mobile Menu */}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default memo(AdminNavbar);
