// utils.ts

/**
 * Extracts the domain from an email address.
 * @param email - The email address to extract the domain from.
 * @returns The domain of the email or an empty string if the input is not a string.
 */
export const extractEmailDomain = (email: string): string => {
  if (typeof email === 'string') {
    return email.substring(email.lastIndexOf('@') + 1).split('.')[0];
  }

  return '';
};

/**
 * Capitalizes the first letter of a string.
 * @param str - The string to capitalize.
 * @returns The input string with its first letter capitalized.
 */
export const capitalizeFirstLetter = (str: string): string => {
  if (typeof str === 'string' && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return '';
};

/**
 * Extracts the application name from a client name string.
 * @param clientName - The client name to process.
 * @throws Will throw an error if the clientName is not a string or is an empty string.
 * @returns The extracted application name.
 */
export const extractAppName = (clientName: string): string => {
  if (!clientName || typeof clientName !== 'string') {
    throw new Error('Invalid input: clientName must be a non-empty string');
  }

  const cleanedClientName = clientName.replace(/\s+/g, ' ').replace(/\s*-\s*/g, '-');
  const splitClientName = cleanedClientName.split('-');

  if (splitClientName.length < 2) {
    throw new Error('Invalid input: clientName must contain a " - " or "-" separator');
  }

  const [, appName] = splitClientName;

  return capitalizeFirstLetter(appName);
};

export const extractClientAppName = (clientName: string): string => {
  if (!clientName || typeof clientName !== 'string') {
    throw new Error('Invalid input: clientName must be a non-empty string');
  }

  const cleanedClientName = clientName.replace(/\s+/g, ' ').replace(/\s*-\s*/g, '-');
  const splitClientName = cleanedClientName.split('-');

  if (splitClientName.length < 2) {
    throw new Error('Invalid input: clientName must contain a " - " or "-" separator');
  }

  const appName = splitClientName[splitClientName.length - 1];

  return capitalizeFirstLetter(appName);
};

/**
 * Capitalizes the first letter of each word in a string.
 * @param input - The string to capitalize.
 * @returns The input string with the first letter of each word capitalized.
 */
export const capitalizeWords = (input: string): string => {
  return input
    .split(' ')
    .map(word => capitalizeFirstLetter(word))
    .join(' ');
};

/**
 * Converts a role identifier to a display name.
 * @param role - The role identifier to convert.
 * @returns The display name for the role.
 */
export const getRoleDisplayName = (role: string): string => {
  switch (role) {
    case 'superAdmin':
      return 'Cubeless Admin';
    case 'admin':
      return 'Admin';
    case 'user':
      return 'User';
    // If for some odd reason this persists, default to 'User'
    case 'regularUser':
      return 'User';
    default:
      return 'User';
  }
};

/**
 * Checks if an email is pending based on a list of pending emails.
 * @param email - The email to check.
 * @param pendingUsers - The list of pending emails.
 * @returns A boolean indicating if the email is pending.
 */
export const isEmailPending = (email: string, pendingUsers: string[]): boolean => {
  return pendingUsers.includes(email);
};

/**
 * Validates an email format.
 * @param email - The email to validate.
 * @returns A boolean indicating if the email is valid.
 */
export const isEmailValid = (email: string): boolean => {
  const regex = /^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[^\s@]{2,}$/;

  return regex.test(email);
};

/**
 * Checks if an email is unique within a list.
 * @param email - The email to check.
 * @param emailList - The list of emails to compare against.
 * @returns A boolean indicating if the email is unique in the list.
 */
export const isEmailUnique = (email: string, emailList: string[]): boolean => {
  return !emailList.includes(email);
};

// Add other functions here similarly with documentation and proper error handling
