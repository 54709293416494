import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  Cog8ToothIcon,
  CreditCardIcon,
  EnvelopeOpenIcon,
  HomeIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useRouter } from 'next/router';

import logo from '../../../assets/cubeless.png';

const navigation = [
  { name: 'Dashboard', href: '/admin-home', icon: HomeIcon },
  { name: 'App Catalog', href: '/admin-home/appCatalog', icon: CheckCircleIcon },
  { name: 'Invitations', href: '/admin-home/invitations', icon: EnvelopeOpenIcon },
  { name: 'User Management', href: '/admin-home/userManagement', icon: UserGroupIcon },
  { name: 'Billing', href: '/admin-home/adminBilling', icon: CreditCardIcon },
  { name: 'Settings', href: '/admin-home/settings', icon: Cog8ToothIcon },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function HomeSidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const router = useRouter();

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <Image className="h-8 w-auto" src={logo} alt="Cubeless" />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map(item => {
                      const isCurrent = router.pathname === item.href;

                      return (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            isCurrent ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                          )}
                        >
                          <item.icon
                            className={classNames(
                              isCurrent ? 'text-gray-100' : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      );
                    })}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 bg-gray-700 p-4" />
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:inset-y-0 md:flex md:w-64 md:flex-col top-16">
        <div className="flex min-h-0 flex-1 flex-col bg-[#2B3D45]">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="mt-5 h-0 flex-1 overflow-y-auto">
              <nav className="space-y-1 px-2">
                {navigation.map(item => {
                  const isCurrent = router.pathname === item.href;

                  return (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        isCurrent ? 'bg-[#FFB238] text-black' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-2xl',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          isCurrent ? 'text-gray-800' : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 flex-shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </a>
                  );
                })}
                <div className="container pt-auto">
                  <div className="flex-1 overflow-y-auto pt-80">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        router.back();
                      }}
                      className="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center px-4 py-2 text-base text-center font-medium rounded-md"
                    >
                      <ArrowLeftIcon
                        className="text-gray-400 group-hover:text-gray-300 mr-16 flex-shrink-0 h-6 w-6"
                        aria-hidden="true"
                      />
                      Back
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
