// PaginationControl.tsx
import React from 'react';
import { Box, Pagination } from '@mui/material';

interface PaginationControlProps {
  pageCount: number;
  currentPage: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, page: number) => void;
}

const PaginationControl: React.FC<PaginationControlProps> = ({ pageCount = 1, currentPage, handlePageChange }) => (
  <Box display="flex" justifyContent="center" mt={2}>
    {pageCount > 0 ? (
      <Pagination
        count={pageCount}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        showFirstButton
        showLastButton
      />
    ) : null}
  </Box>
);

export default PaginationControl;
