import React, { useEffect, useMemo } from 'react';

import type { FC } from 'react';
import type { Category } from '../../services/CategoryService';
import type { CatalogApplication } from '../../services/CatalogService';
import type { UnifiedApplication } from '../../interfaces/Client/Client';

export type CatalogCategoryControlsProps = {
  applications: CatalogApplication[] | UnifiedApplication[];
  categories: Category[];
  onSelectCategory: (uuid: string) => void;
  position?: 'left' | 'right';
  defaultTopCategory?: boolean;
  selectedCategory: string | undefined;
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const baseClass =
  'inline-flex items-center px-4 py-2 text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-gray-900';
const activeClass = 'bg-sun hover:bg-gray-200 border border-solid border-amber-500 ';
const defaultClass = 'bg-gray-200 hover:bg-sun text-gray-900 border border-solid border-gray-400/25';
const CatalogCategoryControls: FC<CatalogCategoryControlsProps> = ({
  applications,
  categories,
  defaultTopCategory = false,
  onSelectCategory,
  position,
  selectedCategory,
}) => {
  const applicationCategories = useMemo(() => {
    const categoryIds = applications.flatMap(app => app.categories);
    const uniqueCategoryIds = Array.from(new Set(categoryIds));

    return [
      {
        uuid: '',
        name: 'All Categories',
      },
      {
        uuid: 'uncategorized',
        name: 'Uncategorized',
      },
      ...categories.filter(category => uniqueCategoryIds.includes(category.uuid)),
    ];
  }, [applications, categories]);

  const topCategory = useMemo(() => {
    return applicationCategories.find(category => category.name === 'Top');
  }, [applicationCategories]);

  useEffect(() => {
    if (defaultTopCategory && topCategory?.uuid) {
      onSelectCategory(topCategory?.uuid);
    }
  }, [defaultTopCategory, onSelectCategory, topCategory]);

  return (
    <search
      className={classNames(
        'flex flex-col justify-center min-w-full',
        position === 'left' ? 'items-start' : 'items-end',
      )}
    >
      <ul className="mt-3 sm:mt-0 w-fit flex items-center gap-3 flex-wrap justify-end">
        {applicationCategories?.map(category => (
          <li key={category.uuid}>
            <button
              onClick={() => onSelectCategory(category.uuid)}
              disabled={selectedCategory === category.uuid}
              className={classNames(baseClass, selectedCategory === category.uuid ? activeClass : defaultClass)}
            >
              {category.name}
            </button>
          </li>
        ))}
      </ul>
    </search>
  );
};

export default CatalogCategoryControls;
