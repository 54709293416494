import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { Elements } from '@stripe/react-stripe-js';
import { captureException, captureMessage } from '@sentry/nextjs';
import { getStripeSubscriptionPriceId } from '../../services';

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string);

export default function StripeCheckout() {
    const router = useRouter();
    const [priceId, setPriceId] = useState<string>('');
    const { success, canceled } = router.query;

    useEffect(() => {
        // Fetch the price ID
        const fetchPriceId = async () => {
            const response = await getStripeSubscriptionPriceId();

            if (response && response.priceId) {
                setPriceId(response.priceId);
            }
        };

        fetchPriceId();

        // Handle success and canceled states
        if (success !== undefined || canceled !== undefined) {
            if (success) {
                captureMessage(
                    'Payment confirmed! See the result in your Stripe dashboard. Refresh the page to pay again.'
                );
            } else {
                captureMessage(
                    'Payment canceled -- continue to shop around and checkout when you’re ready.'
                );
            }
        }
    }, [success, canceled]);

    const handleSubscribe = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        try {
            // Make a POST request to your session creation endpoint
            const response = await fetch('/api/checkout/session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ priceId }), // Send priceId to the backend
            });

            const sessionData = await response.json();

            // Redirect to Stripe checkout page
            if (sessionData.url) {
                window.location.href = sessionData.url;
            } else {
                throw new Error('Failed to redirect to Stripe checkout');
            }
        } catch (err) {
            console.error('Error during Stripe session creation:', err);
            captureException(err);
        }
    };

    return (
        <div className="flex items-center max-w-7xl pl-2 py-6">
            <section className="items-center justify-center mx-auto px-auto">
                <Elements stripe={stripePromise}>
                    <button
                        className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleSubscribe}
                        role="link">
                        <p className="focus:outline-none focus:ring-2 focus:ring-indigo-500">Subscribe</p>
                    </button>
                </Elements>
            </section>
        </div>
    );
}
