import { useEffect, useState } from 'react';
import { Spinner } from '../..';
import { findImageForClient } from '../../../utils/client-app-libs/findImageForClient';
import { getOrganizationByUserId } from '../../../services';
import { captureException } from '@sentry/nextjs';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useUnifiedApplications } from '../../../hooks/useUnifiedApplications';
import { useCustomApplications } from '../../../hooks/useCustomApplications';
import ApplicationItem from '../../appCatalog/ApplicationItem';
import type { OrganizationData } from '../../../interfaces/Organization/Organization';

type WorkspaceApplicationGridProps = {
  isAppsLoading: boolean;
  setIsAppsLoading: (value: boolean) => void;
};

const WorkspaceApplicationGrid = ({ isAppsLoading, setIsAppsLoading }: WorkspaceApplicationGridProps): JSX.Element => {
  const { user, isLoading } = useUser();
  const [organizationData, setOrganizationData] = useState<OrganizationData>(null);

  const { displayedApplications: selectedApplications, isLoading: isUnifiedLoading } = useUnifiedApplications(
    organizationData?.organization.organization_id as string,
  );

  const { urlClients: customApplications, fetchUrlClients } = useCustomApplications(
    organizationData?.organization.organization_id as string,
  );

  useEffect(() => {
    if (!user || isLoading) {
      return;
    }

    const fetchOrganization = async () => {
      setIsAppsLoading(true);

      try {
        const orgData: OrganizationData = await getOrganizationByUserId(user?.sub as string);

        if (orgData && orgData.organization) {
          setOrganizationData({
            organization: {
              organization_id: orgData.organization.organization_id,
              name: orgData.organization.name,
              display_name: orgData.organization.display_name,
            },
            branding: orgData.branding,
          });
        }
      } catch (error) {
        captureException(error);
      } finally {
        setIsAppsLoading(false);
      }
    };

    fetchOrganization();
  }, [user, isLoading, setIsAppsLoading]);

  useEffect(() => {
    if (organizationData?.organization) {
      setIsAppsLoading(true);

      try {
        fetchUrlClients();
      } catch (error) {
        captureException(error);
      } finally {
        setIsAppsLoading(false);
      }
    }
  }, [setIsAppsLoading, fetchUrlClients, organizationData?.organization]);

  if (isAppsLoading || isUnifiedLoading) {
    return (
      <div className="flex items-center justify-center w-full mx-auto col-span-6">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="grid grid-cols-6 gap-3 px-4 py-8 sm:px-0 min-h-full" style={{ flexGrow: 1 }}>
      {selectedApplications
        .sort((a, b) => {
          return a?.name?.localeCompare(b.name as string) || 0;
        })
        .map((app, index) => (
          <ApplicationItem
            key={index}
            application={app}
          />
        ))}
      {customApplications
        .sort(
          (a, b) => (findImageForClient(b.name as string) ? 1 : -1) - (findImageForClient(a.name as string) ? 1 : -1),
        )
        .map((app, index) => (
          <ApplicationItem key={index} application={app} />
        ))}
    </div>
  );
};

export default WorkspaceApplicationGrid;
