import React from 'react';
import Image from 'next/image';
import logo from '../../../assets/cubeless.png';
import vertDash from '../../../assets/vertical-dash.png';
import router from 'next/router';

const HomeNavbar = () => {
    const signOutAuth0 = async () => {
        try {
            await router.push('/api/auth/logout');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="bg-white flex justify-between items-center" style={{ height: '64px' }}>
            <div className="flex-1">
                <div className="h-0 flex flex-shrink-0 items-center px-10">
                    <Image src={logo} alt="Logo" className="h-[40px] w-auto" />
                    <span className="px-[30px]">
                        <Image src={vertDash} alt="seperator" className="h-41 w-auto" />
                    </span>
                </div>
            </div>
            <div className="mx-auto py-6 px-6">
                <button
                    className="mr-4 inline-flex items-center bg-[#fff] hover:bg-[#FFB238] text-black py-2 px-4 rounded-full"
                    type="button"
                    onClick={signOutAuth0}
                >
                    <span className="text-black font-semibold">RESTART</span>
                </button>
            </div>
        </div>
    );
};

export default HomeNavbar;
