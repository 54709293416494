import React from 'react';
import { ApplicationListSection } from '../../components';

import type { ChangeEvent } from 'react';
import type { CreateOrganizationApplicationDto, UnifiedApplication } from '../../interfaces/Client/Client';
import type { Category } from '../../services/CategoryService';

interface ApplicationSectionsProps {
  // Loading states
  isUserLoading: boolean;
  isUnifiedLoading: boolean;
  isLoading: boolean;
  isHandlingApplicationAction?: boolean;

  // Organization and application data
  organizationId: string;
  urlClients: UnifiedApplication[];
  selectedApplications: UnifiedApplication[];
  catalogApplications: UnifiedApplication[];
  fullSelectedApplications: UnifiedApplication[];
  fullCatalogApplications: UnifiedApplication[];
  paginatedSelectedApplications: UnifiedApplication[];
  paginatedCatalogApplications: UnifiedApplication[];

  // Categories
  categories: Category[];
  onSelectCategory: (uuid: string) => void;
  selectedCategory: string;

  // Pagination
  itemsPerPage: number;
  currentPageSelectedApps: number;
  currentPageAppCatalog: number;
  currentPageCustomApps: number;
  pageCountSelectedApps: number;
  pageCountAppCatalog: number;
  pageCountCustomApps: number;

  // Sorting
  selectedSortOption: { title: string; description: string; value: string };
  catalogSortOption: { title: string; description: string; value: string };
  customSortOption: { title: string; description: string; value: string };
  setSelectedSortOption: React.Dispatch<React.SetStateAction<{ title: string; description: string; value: string }>>;
  setCatalogSortOption: React.Dispatch<React.SetStateAction<{ title: string; description: string; value: string }>>;
  setCustomSortOption: React.Dispatch<React.SetStateAction<{ title: string; description: string; value: string }>>;
  sortSelectedApplications: (applications: UnifiedApplication[]) => UnifiedApplication[];
  sortCatalogApplications: (applications: UnifiedApplication[]) => UnifiedApplication[];
  sortCustomApplications: (applications: UnifiedApplication[]) => UnifiedApplication[];

  // Event handlers
  handleEnableCatalogApp?: (application: UnifiedApplication) => Promise<void>;
  handleSelectedToggleStatus: (application: UnifiedApplication) => Promise<void>;
  handlePageChangeSelectedApps: (event: ChangeEvent<unknown>, page: number) => void;
  handlePageChangeAppCatalog: (event: ChangeEvent<unknown>, page: number) => void;
  handlePageChangeCustomApps: (event: ChangeEvent<unknown>, page: number) => void;
  onAdd: (app: { url: string; names: string }) => void;
  handleDelete: (url: string) => Promise<void>;
  handleDeleteSSOClient?: (clientId: string) => Promise<void>;
  updateUnifiedApplications: (
    applicationId: string,
    applicationData: CreateOrganizationApplicationDto,
  ) => Promise<void>;
  setTriggerFetch: (triggerFetch: boolean) => void;
}

const ApplicationSections: React.FC<ApplicationSectionsProps> = ({
  // Loading states
  isUserLoading,
  isUnifiedLoading,
  isLoading,
  isHandlingApplicationAction,

  // Organization and application data
  organizationId,
  urlClients,
  fullSelectedApplications,
  fullCatalogApplications,
  paginatedSelectedApplications,
  paginatedCatalogApplications,

  // Categories
  categories,
  selectedCategory,
  onSelectCategory,

  // Pagination
  currentPageSelectedApps,
  currentPageAppCatalog,
  currentPageCustomApps,
  pageCountSelectedApps,
  pageCountAppCatalog,
  pageCountCustomApps,

  // Sorting
  selectedSortOption,
  catalogSortOption,
  customSortOption,
  setSelectedSortOption,
  setCatalogSortOption,
  setCustomSortOption,
  sortSelectedApplications,
  sortCatalogApplications,
  sortCustomApplications,

  // Event handlers
  handleEnableCatalogApp,
  handleSelectedToggleStatus,
  handlePageChangeSelectedApps,
  handlePageChangeAppCatalog,
  handlePageChangeCustomApps,
  onAdd,
  handleDelete,
  handleDeleteSSOClient,
  updateUnifiedApplications,
  setTriggerFetch,
}) => {
  const sortedSelectedApplications = sortSelectedApplications(fullSelectedApplications);
  const sortedCatalogApplications = sortCatalogApplications(fullCatalogApplications);
  const sortedCustomApplications = sortCustomApplications(urlClients);

  return (
    <>
      <ApplicationListSection
        title="Selected Applications"
        description="These are the current enabled or SSO configured applications accessible to organization members. SSO configured applications will have your organization name prefixed to the application name and display an SSO badge."
        className="mb-4"
        selectedApplications={sortedSelectedApplications}
        paginatedSelectedApplications={paginatedSelectedApplications}
        onToggle={handleSelectedToggleStatus}
        isRemovable
        isLoading={isHandlingApplicationAction ? false : isUnifiedLoading || isUserLoading || isLoading}
        organizationId={organizationId}
        selectedSortOption={selectedSortOption}
        setSelectedSortOption={setSelectedSortOption}
        sortApplications={sortSelectedApplications}
        pageCount={pageCountSelectedApps}
        currentPage={currentPageSelectedApps}
        handlePageChange={handlePageChangeSelectedApps}
        sortOptions={[selectedSortOption]}
        updateUnifiedApplications={updateUnifiedApplications}
        handleDeleteSSOClient={handleDeleteSSOClient}
        setTriggerFetch={setTriggerFetch}
      />
      <ApplicationListSection
        catalogApplications={sortedCatalogApplications}
        categories={categories}
        className="mb-4"
        currentPage={currentPageAppCatalog}
        description="Enable or configure SSO integration for applications in the catalog to allow organization members to access these applications."
        handlePageChange={handlePageChangeAppCatalog}
        isLoading={isHandlingApplicationAction ? false : isUnifiedLoading || isUserLoading || isLoading}
        isRemovable={false}
        onEnableCatalogApp={handleEnableCatalogApp}
        onSelectCategory={onSelectCategory}
        onToggle={handleSelectedToggleStatus}
        organizationId={organizationId}
        pageCount={pageCountAppCatalog}
        paginatedCatalogApplications={paginatedCatalogApplications}
        selectedCategory={selectedCategory}
        selectedSortOption={catalogSortOption}
        setSelectedSortOption={setCatalogSortOption}
        setTriggerFetch={setTriggerFetch}
        sortApplications={sortCatalogApplications}
        sortOptions={[catalogSortOption]}
        title="Application Catalog"
        updateUnifiedApplications={updateUnifiedApplications}
      />
      <ApplicationListSection
        title="Custom Applications"
        description="Add custom applications by name and url for organization members to access these applications."
        urlClients={sortedCustomApplications}
        onDelete={handleDelete}
        isRemovable
        organizationId={organizationId}
        isLoading={isHandlingApplicationAction ? false : isUnifiedLoading || isUserLoading || isLoading}
        onAdd={onAdd}
        selectedSortOption={customSortOption}
        setSelectedSortOption={setCustomSortOption}
        sortApplications={sortCustomApplications}
        pageCount={pageCountCustomApps}
        currentPage={currentPageCustomApps}
        handlePageChange={handlePageChangeCustomApps}
        sortOptions={[customSortOption]}
        updateUnifiedApplications={updateUnifiedApplications}
        setTriggerFetch={setTriggerFetch}
      />
    </>
  );
};

export default ApplicationSections;
