import RemovePendingUserIcon from '../utilities/Icons/RemovePendingUserIcon';
import { type Invitation } from '../../interfaces/OrganizationInvitations/Invitation';

type InvitationsListProps = {
    invitations: Invitation[] | string[];
    onDelete: (email: string) => void; // Add this prop for deletion function
}

const styles = {
    main: `max-h-64 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg bg-[#FFB238] mt-8`,
    table: `min-w-full divide-y divide-gray-300`,
    th: `bg-sun py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-slate`,
    td: `whitespace-nowrap px-3 py-4 text-sm text-slate`,
};

export const InvitationsList = ({ invitations, onDelete }: InvitationsListProps) => {
    const handleDelete = (email: string) => {
        onDelete(email);
    };

    return (
        <div className={styles.main}>
            <table className={styles.table}>
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className={styles.th}>Email</th>
                        <th scope="col" className={styles.th}>Status</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-dashed divide-slate bg-white">
                    {invitations.map((invitation) => {
                        const email = typeof invitation === 'string' ? invitation : invitation.email;
                        const statusText = typeof invitation === 'string' ? 'Pending' : (invitation.accepted ? 'Accepted' : 'Pending');

                        return (
                            <tr key={email}>
                                <td className={styles.td}>{email}</td>
                                <td className={styles.td}>{statusText}</td>
                                <td className={styles.td}>
                                    {statusText === 'Pending' && (
                                        <RemovePendingUserIcon
                                            onClick={() => handleDelete(email)}
                                            className="cursor-pointer text-red-500 hover:text-red-700"
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};


export default InvitationsList;
