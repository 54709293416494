import React, { useState } from 'react';
import Image from 'next/image';
import { TrashIcon } from '@heroicons/react/20/solid';
import { type UnifiedApplication } from '../../interfaces/Client/Client';

interface CustomApplicationProps {
  application: UnifiedApplication;
  onDelete: (url: string) => Promise<void>;
  isRemovable: boolean;
}

const CustomApplication: React.FC<CustomApplicationProps> = ({ application, onDelete, isRemovable }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await onDelete(application.url as string);
    setIsDeleting(false);
  };

  return (
    <div className="flex flex-col items-center justify-between pt-4 bg-white rounded-lg shadow border border-gray-200 space-y-3 max-w-xs transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">
      <div className="w-full flex justify-center">
        <Image
          src={application.logo || '/cubeless_white_logo.svg'}
          alt={`${application.name} Logo`}
          width={80}
          height={80}
          className="rounded-md aspect-square"
        />
      </div>
      <div className="text-center">
        <a
          href={application.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-md font-medium text-gray-800 flex items-center justify-center gap-2"
        >
          {application.name}
        </a>
      </div>
      {isRemovable && (
        <div className="relative inline-block text-left w-full">
          <button
            type="button"
            className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-red-700 bg-white border-t border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            onClick={() => handleDelete()}
          >
            <TrashIcon className="w-5 h-5 mr-3" aria-hidden="true" />
            {isDeleting ? (
              <>
                <svg
                  className="animate-spin h-4 w-4 text-red-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <div className="ml-2">
                  Deleting...
                </div>
              </>
            ) : (
              'Delete'
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomApplication;
