// hooks/useFilteredApplicationList.ts
import { useCallback, useState } from 'react';
import { type UnifiedApplication } from '../interfaces/Client/Client';

const useFilteredApplicationList = (
  urlClients?: UnifiedApplication[],
  selectedApplications?: UnifiedApplication[],
  catalogApplications?: UnifiedApplication[],
) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filterApplications = useCallback(
    (applications: UnifiedApplication[] | undefined) => {
      return applications?.filter(application => application.name?.toLowerCase().includes(searchTerm.toLowerCase()));
    },
    [searchTerm],
  );

  const filteredUrlClients = filterApplications(urlClients);
  const filteredSelectedApplications = filterApplications(selectedApplications);
  const filteredCatalogApplications = filterApplications(catalogApplications);

  return {
    searchTerm,
    setSearchTerm,
    filteredUrlClients,
    filteredSelectedApplications,
    filteredCatalogApplications,
  };
};

export default useFilteredApplicationList;
