import React from 'react';

export function CatalogEmpty(props: { onClick: () => void }) {
  return (
    <div className="col-span-full flex flex-col items-center justify-center min-h-64 gap-y-8">
      <p className="text-lg text-gray-500">No applications found in this category.</p>
      <button
        type="button"
        className="ml-1 rounded-full border border-transparent bg-seaBlue text-white col-span-4 px-8 py-3 text-sm font-semibold shadow-sm hover:bg-sun hover:text-slate focus:outline-none focus:ring-2 focus:ring-seaBlue"
        onClick={props.onClick}
      >
        View All Applications
      </button>
    </div>
  );
}
