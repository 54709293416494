import type { FC } from 'react';
import React from 'react';

type TextInputProps = {
  id: string;
  label?: string;
  name: string;
  value?: string;
  onChange: (value: string) => void;
  error?: boolean;
  errorMessage?: string;
  description?: string;
  placeholder?: string;
  className?: string;
};

export const TextInput: FC<TextInputProps> = ({
  id,
  label,
  name,
  value,
  onChange,
  error,
  errorMessage,
  description,
  placeholder,
  className,
}) => {
  const descriptionText = description && <p className="mt-2 text-sm leading-6 text-gray-600">{description}</p>;
  const errorText = error && <p className="text-sm text-red-500 mt-1">{errorMessage}</p>;

  return (
    <>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
          {label}
        </label>
      )}
      <div className="mt-2">
        <input
          type="text"
          name={name}
          id={id} // Use the id prop for the input's id attribute
          placeholder={placeholder}
          value={value}
          onChange={event => onChange(event.target.value)}
          className={className}
        />
      </div>
      {error ? errorText : descriptionText}
    </>
  );
};

export default TextInput;
