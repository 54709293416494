import { useRouter } from 'next/router';
import { type MouseEvent, type FunctionComponent } from 'react';
import styles from '../../../styles/Navbar.module.css';

/**
 * Auth0SignUpButton Component.
 * Triggers a redirection to the Auth0 Universal Login Page with a signup hint when clicked.
 *
 * @component
 * @example
 * return (
 *   <Auth0SignUpButton />
 * )
 */
const Auth0SignUpButton: FunctionComponent = () => {
    const router = useRouter();

    /**
     * Event handler for the button click event.
     * Initiates a redirection to the Auth0 signup page.
     *
     * @param {MouseEvent<HTMLAnchorElement>} e - The click event object.
     * @returns {Promise<void>} - A promise that resolves when the redirection is initiated.
     */
    const signupWithRedirect = async (e: MouseEvent<HTMLButtonElement>): Promise<void> => {
        e.preventDefault();

        try {
            await router.push('/api/auth/signup');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <button
            className="inline-flex items-center bg-gray-500 text-white py-2 px-4 rounded-full"
            type="button"
            onClick={signupWithRedirect}
        >
            <span className={styles.signUpButtonText}>SIGN UP</span>
        </button>
    );
};

export default Auth0SignUpButton;
