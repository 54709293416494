import { useState, useEffect } from 'react';
import { type UserAuth0Api } from '../../interfaces/User/UserAuth0ApiType';
import { getMembersWhoBelongToAnOrganization, getUsers } from '../../services';
import { Spinner } from '..';
import { captureException, captureMessage } from '@sentry/nextjs';

export default function Statistics({ organizationId }: { organizationId: string }) {
  const [active, setActive] = useState<number>();
  const [users24HoursCount, setUsers24HoursCount] = useState<number>(0);
  const [users30DaysCount, setUsers30DaysCount] = useState<number>(0);
  const [loadingActive, setLoadingActive] = useState(true);
  const [loadingUsers24Hours, setLoadingUsers24Hours] = useState(true);
  const [loadingUsers30Days, setLoadingUsers30Days] = useState(true);
  const [members, setMembers] = useState([]);
  const [hasFetchedActiveUsers, setHasFetchedActiveUsers] = useState(false);
  const [hasFetchedUsers, setHasFetchedUsers] = useState(false);

  useEffect(() => {
    const fetchActiveUsers = async () => {
      if (!organizationId || organizationId === '' || hasFetchedActiveUsers) {
        return;
      }

      try {
        const membersData = await getMembersWhoBelongToAnOrganization(organizationId);

        setActive(membersData.length);
        setMembers(membersData);
        setLoadingActive(false);
        setHasFetchedActiveUsers(true);
      } catch (error) {
        captureMessage('Failed to fetch organization in fetchStats');
        captureException(error);
        console.error('Failed to fetch organization in fetchStats:', error);
      }
    };

    fetchActiveUsers();
  }, [organizationId, hasFetchedActiveUsers]);

  useEffect(() => {
    const fetchUsersStats = async () => {
      if (members.length && !hasFetchedUsers) {
        try {
          const allUsers = await getUsers();

          setHasFetchedUsers(true); // Set it to true here

          // Get the user objects from allUsers that match the user IDs in members
          const matchingUsers = allUsers.filter((user: UserAuth0Api) =>
            members.some((member: { user_id: string }) => member.user_id === user.user_id),
          );

          const currentDate = new Date().getTime() / 1000;
          const twentyFourHoursAgo = currentDate - 24 * 60 * 60;
          const thirtyDaysAgo = currentDate - 30 * 24 * 60 * 60;

          matchingUsers.forEach((user: UserAuth0Api) => {
            const lastUpdate = new Date(user.updated_at).getTime(); // Use updated_at instead of last_login

            if (lastUpdate > twentyFourHoursAgo * 1000) {
              // Multiply by 1000
              setUsers24HoursCount(prevCount => prevCount + 1); // Update users24HoursCount
            }

            if (lastUpdate > thirtyDaysAgo * 1000) {
              // Multiply by 1000
              setUsers30DaysCount(prevCount => prevCount + 1); // Update users30DaysCount
            }
          });

          // Set loading states to false
          setLoadingUsers24Hours(false);
          setLoadingUsers30Days(false);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchUsersStats();
  }, [members, hasFetchedUsers]);

  return (
    <div className="px-4">
      <h3 className="text-xl font-medium text-left leading-6 text-gray-900">User Statistics</h3>
      <dl className="mt-5 grid grid-cols-1 gap-4 md:grid-cols-3">
        <div key="Active Users" className="px-4 py-5 sm:p-6 flex flex-col bg-white shadow-md rounded-lg items-left">
          <dd className="flex items-left justify-start">
            <div className="text-5xl text-left font-semibold text-slate">
              {loadingActive ? (
                <div className="ml-2">
                  <Spinner />
                </div>
              ) : (
                <div className="text-5xl text-left font-semibold text-slate">{active}</div>
              )}
            </div>
          </dd>
          <dt className="text-left font-normal text-gray-900 mt-2">Total Active Users</dt>
        </div>
        <div
          key="Active Users in last 24 Hours"
          className="px-4 py-5 sm:p-6 flex flex-col bg-white shadow-md rounded-lg items-left"
        >
          <dd className="flex items-left justify-start">
            <div className="text-5xl text-left font-semibold text-slate">
              {loadingUsers24Hours ? (
                <div className="ml-2">
                  <Spinner />
                </div>
              ) : (
                <div className="text-5xl text-left font-semibold text-slate">{users24HoursCount}</div>
              )}
            </div>
          </dd>
          <dt className="text-left font-normal text-gray-900 mt-2">Active Users in last 24 Hours</dt>
        </div>
        <div
          key="Signed in Last 30 Days"
          className="px-4 py-5 sm:p-6 flex flex-col bg-white shadow-md rounded-lg items-left"
        >
          <dd className="flex items-left justify-start">
            <div className="text-5xl text-left font-semibold text-slate">
              {loadingUsers30Days ? (
                <div className="ml-2">
                  <Spinner />
                </div>
              ) : (
                <div className="text-5xl text-left font-semibold text-slate">{users30DaysCount}</div>
              )}
            </div>
          </dd>
          <dt className="text-left font-normal text-gray-900 mt-2">Signed in Last 30 Days</dt>
        </div>
      </dl>
    </div>
  );
}
