import {
  adp,
  adpworkforcenow,
  aha,
  asana,
  bamboohr,
  box,
  datadog,
  docusign,
  dropbox,
  figma,
  freshbooks,
  freshdesk,
  github,
  gmail,
  googleLogo,
  greenhouse,
  gusto,
  hubspot,
  lever,
  monday,
  mongo,
  netsuite,
  office365,
  outlook,
  quickbooks,
  rippling,
  salesforce,
  servicenow,
  slack,
  splunk,
  sumologic,
  zendesk,
  zoho,
  zoom,
} from './logos';

export const findImageForClient = (clientName: string) => {
  if (!clientName) {
    return null; // or handle the case where clientName is not defined
  }

  const lowercaseClientName = clientName?.toLowerCase?.();

  switch (lowercaseClientName) {
    case 'adp':
      return adp;
    case 'adp workforce':
    case 'adp-workforce':
    case 'adpworkforce':
    case 'adp workforce now':
    case 'adp-workforce-now':
    case 'adpworkforcenow':
      return adpworkforcenow;
    case 'aha':
    case 'aha!':
      return aha;
    case 'asana':
      return asana;
    case 'bamboohr':
      return bamboohr;
    case 'box':
      return box;
    case 'datadog':
    case 'data dog':
      return datadog;
    case 'docusign':
      return docusign;
    case 'dropbox':
      return dropbox;
    case 'freshdesk':
    case 'fresh desk':
    case 'fresh-desk':
    case 'freshdesks':
      return freshdesk;
    case 'freshbook':
    case 'freshbooks':
      return freshbooks;
    case 'figma':
      return figma;
    case 'greenhouse':
    case 'green house':
    case 'green-house':
      return greenhouse;
    case 'github':
      return github;
    case 'gmail':
      return gmail;
    case 'google':
    case 'google workspace':
    case 'google-workspace':
    case 'googleworkspace':
    case 'google suite':
      return googleLogo;
    case 'gusto':
      return gusto;
    case 'hubspot':
      return hubspot;
    case 'lever':
      return lever;
    case 'monday':
      return monday;
    case 'mongo db':
    case 'mongo':
    case 'mongodb':
      return mongo;
    case 'netsuite':
      return netsuite;
    case 'office 365':
    case 'office365':
      return office365;
    case 'outlook':
      return outlook;
    case 'quickbooks':
      return quickbooks;
    case 'rippling':
      return rippling;
    case 'sales force':
    case 'sales-force':
    case 'salesforce':
      return salesforce;
    case 'servicenow':
      return servicenow;
    case 'slack':
      return slack;
    case 'splunk':
      return splunk;
    case 'sumologic':
    case 'sumo logic':
      return sumologic;
    case 'zendesk':
      return zendesk;
    case 'zoho':
      return zoho;
    case 'zoom':
      return zoom;
    default:
      return ''; // Return an empty string or a default image if the clientName is not found
  }
};
