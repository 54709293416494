// ApplicationListSection.tsx
import React from 'react';
import { ApplicationList, PaginationControl } from '..';

import type { ChangeEvent } from 'react';
import type { Category } from '../../services/CategoryService';
import type { CreateOrganizationApplicationDto, UnifiedApplication } from '../../interfaces/Client/Client';

interface ApplicationListSectionProps {
  // UI options
  title: string;
  description?: string;
  className?: string;
  application?: UnifiedApplication;
  isRemovable?: boolean;

  // Loading state
  isLoading?: boolean;

  // Organization and application data
  organizationId?: string;
  urlClients?: UnifiedApplication[];
  selectedApplications?: UnifiedApplication[];
  fullSelectedApplications?: UnifiedApplication[];
  paginatedSelectedApplications?: UnifiedApplication[];
  catalogApplications?: UnifiedApplication[];
  fullCatalogApplications?: UnifiedApplication[];
  paginatedCatalogApplications?: UnifiedApplication[];

  // Categories
  categories?: Category[];
  selectedCategory?: string;
  onSelectCategory?: (uuid: string) => void;

  // Sorting
  selectedSortOption: { title: string; description: string; value: string };
  setSelectedSortOption: React.Dispatch<React.SetStateAction<{ title: string; description: string; value: string }>>;
  sortApplications: (
    applications: UnifiedApplication[],
    sortOption: { title: string; description: string; value: string },
  ) => UnifiedApplication[];
  sortOptions: { title: string; description: string; value: string }[];

  // Pagination
  pageCount?: number;
  currentPage?: number;
  handlePageChange?: (event: ChangeEvent<unknown>, page: number) => void;

  // Event handlers
  onToggle?: (application: UnifiedApplication) => Promise<void>;
  onDelete?: (url: string) => Promise<void>;
  handleDeleteSSOClient?: (clientId: string) => Promise<void>;
  onEnableCatalogApp?: (application: UnifiedApplication) => Promise<void>;
  onAdd?: (app: { url: string; names: string }) => void;
  updateUnifiedApplications: (
    applicationId: string,
    applicationData: CreateOrganizationApplicationDto,
  ) => Promise<void>;
  setTriggerFetch: (triggerFetch: boolean) => void;
}

const ApplicationListSection: React.FC<ApplicationListSectionProps> = ({
  // UI options
  title,
  description = '',
  className = '',
  isRemovable,

  // Loading state
  isLoading,

  // Organization and application data
  organizationId,
  urlClients,
  selectedApplications,
  paginatedSelectedApplications,
  catalogApplications,
  paginatedCatalogApplications,

  // Categories
  categories,
  onSelectCategory,
  selectedCategory,

  // Sorting
  selectedSortOption,
  setSelectedSortOption,
  sortApplications,

  // Pagination
  pageCount,
  currentPage,
  handlePageChange,

  // Event handlers
  onToggle,
  onDelete,
  handleDeleteSSOClient,
  onEnableCatalogApp,
  onAdd,
  updateUnifiedApplications,
  setTriggerFetch,
}) => (
  <div className={`mt-4 mb-8 ${className} flex flex-col`}>
    <div>
      <h2 className="text-2xl font-semibold mb-2">{title}</h2>
      {description && <p className="text-sm text-gray-600 mb-4">{description}</p>}
    </div>
    {!isLoading && Array.isArray(selectedApplications) && selectedApplications.length === 0 && !urlClients?.length ? (
      <div className="col-span-full text-center">
        <p className="font-medium text-md">
          No applications are currently enabled for your organization. Please enable them from the App Catalog section
          below.
        </p>
      </div>
    ) : (
      <>
        {Array.isArray(urlClients) && urlClients.length === 0 && title === 'Custom Applications' && (
          <div className="col-span-full text-center">
            <p className="font-medium text-md">
              No custom applications are currently added. Please add them in the Custom Applications section below.
            </p>
          </div>
        )}
        <ApplicationList
          categories={categories}
          urlClients={urlClients}
          selectedApplications={selectedApplications}
          paginatedSelectedApplications={paginatedSelectedApplications}
          catalogApplications={catalogApplications}
          paginatedCatalogApplications={paginatedCatalogApplications}
          onToggle={onToggle}
          onDelete={onDelete}
          isRemovable={isRemovable ?? false}
          onEnableCatalogApp={onEnableCatalogApp}
          organizationId={organizationId}
          onAdd={onAdd}
          onSelectCategory={onSelectCategory}
          showAddButton={title === 'Custom Applications'}
          selectedSortOption={selectedSortOption}
          selectedCategory={selectedCategory}
          setSelectedSortOption={setSelectedSortOption}
          sortApplications={sortApplications}
          isLoading={isLoading}
          updateUnifiedApplications={updateUnifiedApplications}
          handleDeleteSSOClient={handleDeleteSSOClient}
          setTriggerFetch={setTriggerFetch}
        />
        {typeof pageCount === 'number' && typeof currentPage === 'number' && handlePageChange && pageCount > 0 && (
          <PaginationControl pageCount={pageCount} currentPage={currentPage} handlePageChange={handlePageChange} />
        )}
      </>
    )}
  </div>
);

export default ApplicationListSection;
