// ApplicationList.tsx
import React, { useMemo, useState } from 'react';
import CustomApplicationItem from './CustomApplicationItem';
import SelectedApplicationItem from './SelectedApplicationItem';
import CatalogApplicationItem from './CatalogApplicationItem';
import SearchBar from '../utilities/SearchInputBar';
import Spinner from '../utilities/Spinner';
import useFilteredApplicationList from '../../hooks/useFilteredApplicationList';
import CustomApplicationForm from '../forms/CustomApplicationForm';
import { SquaresPlusIcon } from '@heroicons/react/20/solid';

import type { CreateOrganizationApplicationDto, UnifiedApplication } from '../../interfaces/Client/Client';
import type { Category } from '../../services/CategoryService';
import CatalogSortSelect from '../catalog/CatalogSortSelect';
import CatalogCategoryControls from '../catalog/CatalogCategoryControls';
import { CatalogEmpty } from '../catalog/CatalogEmpty';

interface ApplicationListProps {
  // Loading state
  isLoading?: boolean;

  // Organization and application data
  organizationId?: string;
  urlClients?: UnifiedApplication[];
  selectedApplications?: UnifiedApplication[];
  catalogApplications?: UnifiedApplication[];
  paginatedSelectedApplications?: UnifiedApplication[];
  paginatedCatalogApplications?: UnifiedApplication[];

  // Categories
  categories?: Category[];
  selectedCategory?: string;
  onSelectCategory?: (uuid: string) => void;

  // Sorting
  selectedSortOption: { title: string; description: string; value: string };
  setSelectedSortOption: React.Dispatch<React.SetStateAction<{ title: string; description: string; value: string }>>;
  sortApplications: (
    applications: UnifiedApplication[],
    sortOption: { title: string; description: string; value: string },
  ) => UnifiedApplication[];

  // Event handlers
  onToggle?: (application: UnifiedApplication) => Promise<void>;
  onDelete?: (url: string) => Promise<void>;
  handleDeleteSSOClient?: (clientId: string) => Promise<void>;
  onEnableCatalogApp?: (application: UnifiedApplication) => Promise<void>;
  onAdd?: (app: { url: string; names: string }) => void;
  updateUnifiedApplications: (
    applicationId: string,
    applicationData: CreateOrganizationApplicationDto,
  ) => Promise<void>;
  setTriggerFetch: (triggerFetch: boolean) => void;

  // UI options
  isRemovable?: boolean;
  showAddButton?: boolean;
}

const ApplicationList: React.FC<ApplicationListProps> = ({
  // Loading state
  isLoading,

  // Organization and application data
  organizationId,
  urlClients,
  selectedApplications,
  catalogApplications,
  paginatedSelectedApplications,
  paginatedCatalogApplications,

  // Categories
  categories,
  selectedCategory,
  onSelectCategory,

  // Sorting
  selectedSortOption,
  setSelectedSortOption,
  sortApplications,

  // Event handlers
  onToggle,
  onDelete,
  handleDeleteSSOClient,
  onEnableCatalogApp,
  onAdd,
  updateUnifiedApplications,
  setTriggerFetch,

  // UI options
  isRemovable,
  showAddButton = false,
}) => {
  const { searchTerm, setSearchTerm, filteredUrlClients, filteredSelectedApplications, filteredCatalogApplications } =
    useFilteredApplicationList(urlClients, selectedApplications, catalogApplications);

  const [isAddAppModalOpen, setIsAddAppModalOpen] = useState(false);

  const handleAddAppModalClose = () => {
    setIsAddAppModalOpen(false);
  };

  const handleAddAppModalOpen = () => {
    setIsAddAppModalOpen(true);
  };

  const displayedUrlClients = useMemo(
    () => sortApplications((searchTerm ? filteredUrlClients : urlClients) || [], selectedSortOption),
    [searchTerm, sortApplications, filteredUrlClients, urlClients, selectedSortOption],
  );

  const displayedSelectedApplications = useMemo(
    () =>
      sortApplications(
        (searchTerm ? filteredSelectedApplications : paginatedSelectedApplications) || [],
        selectedSortOption,
      ),
    [searchTerm, sortApplications, filteredSelectedApplications, paginatedSelectedApplications, selectedSortOption],
  );

  const displayedCatalogApplications = useMemo(
    () =>
      sortApplications(
        (searchTerm ? filteredCatalogApplications : paginatedCatalogApplications) || [],
        selectedSortOption,
      ),
    [searchTerm, sortApplications, filteredCatalogApplications, paginatedCatalogApplications, selectedSortOption],
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <div className="sticky top-0 z-10">
        <div className="flex flex-col gap-4 items-end justify-end">
          <div className="flex flex-col sm:flex-row gap-2 justify-end items-center w-1/2">
            <SearchBar value={searchTerm} onChange={setSearchTerm} className="flex-auto" />

            <div className="w-fit min">
              <CatalogSortSelect value={selectedSortOption.value} onChange={setSelectedSortOption} />
            </div>

            {showAddButton && (
              <button
                onClick={handleAddAppModalOpen}
                className="rounded-xl bg-seaBlue px-4 py-2.5 text-sm font-semibold text-white shadow-lg hover:bg-sun hover:text-slate focus:outline-none focus:ring-2 focus:ring-seaBlue focus:ring-offset-1 whitespace-nowrap"
              >
                <div className="flex items-center justify-between">
                  <SquaresPlusIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                  Add Application
                </div>
              </button>
            )}
          </div>
          {categories?.length && onSelectCategory && (
            <CatalogCategoryControls
              applications={catalogApplications ?? []}
              categories={categories}
              defaultTopCategory
              onSelectCategory={onSelectCategory}
              selectedCategory={selectedCategory}
            />
          )}
        </div>
      </div>

      <div className="py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-6 gap-6">
          {(displayedUrlClients ?? []).map((application: UnifiedApplication, index: number) => (
            <CustomApplicationItem
              key={index}
              application={application}
              onDelete={onDelete ?? (() => Promise.resolve())}
              isRemovable={isRemovable ?? false}
            />
          ))}
          {(displayedSelectedApplications ?? []).map((application, index) => (
            <SelectedApplicationItem
              key={index}
              application={application}
              onToggle={onToggle ?? (() => Promise.resolve())}
              isRemovable={isRemovable}
              organizationId={organizationId}
              updateUnifiedApplications={updateUnifiedApplications}
              handleDeleteSSOClient={handleDeleteSSOClient}
              setTriggerFetch={setTriggerFetch}
              hasSSOCounterpart={application.hasSSOCounterpart}
            />
          ))}
          {(displayedCatalogApplications ?? []).map((application: UnifiedApplication, index: number) => (
            <CatalogApplicationItem
              key={index}
              application={application}
              onEnableCatalogApp={onEnableCatalogApp ?? (() => Promise.resolve())}
              organizationId={organizationId}
            />
          ))}

          {/*// Empty state for catalog*/}
          {displayedCatalogApplications?.length === 0 && categories?.length && (
            <CatalogEmpty onClick={() => onSelectCategory?.('')} />
          )}
        </div>
      </div>
      {isAddAppModalOpen && (
        <CustomApplicationForm
          isOpen={isAddAppModalOpen}
          onClose={handleAddAppModalClose}
          onSubmit={onAdd as (app: { url: string; names: string }) => void}
        />
      )}
    </div>
  );
};

export default ApplicationList;
