import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ArrowDownCircleIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid';
import { Divider } from '@mui/material';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const classes = {
  dropdownButton: `
    text-center justify-center
    bg-white text-normal border w-full border-slate 
    focus:border-black focus:outline-none
    rounded-sm shadow-sm hover:bg-gray
    w-[100%] text-[#9ca3af] pt-3
  `,
  dropdownOptions: `
    w-56 rounded-md bg-white 
    transform opacity-100 scale-100 
    ring-1 ring-gray-300 mt-3.5
  `,
};

export const SortDropdown = ({ callback }: { callback: (str: string) => void }) => {
  return (
    <Menu>
      <Menu.Button className="bg-white text-normal border w-full border-gray-400 mt-2 focus:border-seaBlue focus:outline-none rounded-md shadow-sm hover:bg-gray text-center">
        <div className="text-gray-400 pt-3">
          Sort <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {/*
        NOTE: the Menu.Items component upon load it automatically injects
        "transform opacity-100 scale-100" to className"
        */}
        <Menu.Items className={classes.dropdownOptions}>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-3 text-sm flex items-center justify-between'
                  )}
                  onClick={() => {
                    callback('asc');
                  }}
                >
                  Ascending <ArrowUpCircleIcon className="ml-2 h-5 w-5" />
                </a>
              )}
            </Menu.Item>
            <Divider />
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-3 text-sm flex items-center justify-between'
                  )}
                  onClick={() => {
                    callback('desc');
                  }}
                >
                  Descending <ArrowDownCircleIcon className="ml-2 h-5 w-5" />
                </a>
              )}
            </Menu.Item>
            {/* NOTE: Commented out for now once backend data models are updated we can implement a sorting by "recently added" */}
            {/*
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm'
                    )}
                    onClick={()=> {
                      callback('recent');
                    }}
                  >
                    Recently Added
                  </a>
                )}
              </Menu.Item>
            */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default SortDropdown;
