import { useState, useEffect, useRef } from 'react';

const useMessage = (timeout = 5000) => {
    const [message, setMessage] = useState<string>('');
    const timeoutRef = useRef<number | null>(null);

    const clearMessageTimeout = () => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
    };

    const showMessage = (newMessage: string) => {
        clearMessageTimeout(); // Clear any existing timeout
        setMessage(newMessage);
        // Set a new timeout
        timeoutRef.current = window.setTimeout(() => {
            setMessage('');
            timeoutRef.current = null;
        }, timeout);
    };

    // Clear the timeout when the component using this hook is unmounted
    useEffect(() => {
        return () => {
            clearMessageTimeout();
        };
    }, []);

    return [message, showMessage] as const;
};

export default useMessage;
