import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import type { Role } from '../../../interfaces/User/UserAuth0ApiType';
import { ROLES_MAP, classNames } from '../../../utils/constants';

type RolesDropDownProps = {
  onChange: (role: string, userId: string) => void;
  userId: string
  disabled: boolean
  roleId: string
}

const styles = {
  ListboxButton: `relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6`,
  ListboxOptions: `absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`,
};

const defaultRole: Role = {
  id: '', // some default id
  name: '', // some default name
  description: '', // some default description
};

export default function RolesDropDown({ onChange, roleId, userId, disabled}: RolesDropDownProps) {
  const selectedRole = ROLES_MAP.find((role) => role.id === roleId) || defaultRole; // Fallback to defaultRole
  const [selected, setSelected] = useState<Role>(selectedRole);

  return (
    <Listbox data-testid="role-dropdown" value={selected}>
      {({ open }) => (
        <div className="relative mt-2">
            <Listbox.Button className={`${disabled ? 'pointer-events-none' : ' '} ${styles.ListboxButton}`}>
              <span className="block truncate">{selected.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className={styles.ListboxOptions}>
                {ROLES_MAP.map((role) => (
                  <Listbox.Option
                    key={role.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={role}
                    onClick={() => {
                      onChange(
                        userId,
                        role.id
                      );
                      setSelected(role);
                    }}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {role.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
        </div>
      )}
    </Listbox>
  );
}
