import React from 'react';
import Image from 'next/image';
import { type UnifiedApplication } from '../../interfaces/Client/Client';

interface ApplicationItemProps {
  application: UnifiedApplication;
}

export const ApplicationItem: React.FC<ApplicationItemProps> = ({ application }) => {
  application.isUrlClient = application.hasOwnProperty('url') && Boolean(application.url);

  let applicationImage: string | undefined;

  if ('logo' in application) {
    applicationImage =
      'isUrlClient' in application && application.isUrlClient
        ? '/cubeless_white_logo.svg'
        : application.logo || '/cubeless_white_logo.svg';
  }

  return (
    <a
      href={application.isUrlClient ? application.url : application.customUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
    >
      <div className="flex flex-col items-center justify-between p-4 bg-white rounded-lg shadow border border-gray-200 space-y-3 max-w-4xl min-h-[13rem] max-h-[14rem]">
        <div className="w-full flex justify-center">
          <Image
            src={applicationImage || '/cubeless_white_logo.svg'}
            alt={`${application.name} Logo`}
            width={80}
            height={80}
            className="rounded-md aspect-square"
          />
        </div>
        <div className="text-center">
          <span className="text-md font-medium text-gray-800 overflow-hidden overflow-ellipsis whitespace">
            {application.name}
          </span>
          {application.type === 'ssoEnabled' && (
            <div className="m-1 inline-flex items-center rounded-md py-1 px-2 text-xs font-medium bg-gray-200 text-gray-900">
              SSO
            </div>
          )}
        </div>
      </div>
    </a>
  );
};

export default ApplicationItem;
