// hooks/Invitations/useInvitationForm.ts
import { useCallback, useState } from 'react';
import {
  addUsersFromInviteListToOrgById,
  deletePendingUserByEmail,
  getMembersWhoBelongToAnOrganization,
  getPendingUserByOrgId,
  getUserByEmail,
  sendInvitationEmail,
} from '../../services';
import { isEmailUnique, isEmailValid } from '../../utils';
import { captureMessage } from '@sentry/nextjs';
import type { OrganizationData } from '../../interfaces/Organization/Organization';

const useInvitationForm = (organizationData: OrganizationData, pendingUsers: string[]) => {
  const [emailValue, setEmailValue] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchedPendingUsers, setFetchedPendingUsers] = useState<string[]>(pendingUsers);

  // Function to fetch pending users and remove duplicates based on current members
  const fetchPendingUsersAndRemoveDuplicates = useCallback(async () => {
    if (organizationData?.organization.organization_id) {
      try {
        const [pendingUsersResponse, currentMembersResponse] = await Promise.all([
          getPendingUserByOrgId(organizationData?.organization.organization_id),
          getMembersWhoBelongToAnOrganization(organizationData?.organization.organization_id),
        ]);

        const currentMemberEmails = new Set(currentMembersResponse.map((member: { email: string }) => member.email));

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const filteredPendingUsers = Array.from(
          new Set(pendingUsersResponse.filter((email: string) => !currentMemberEmails.has(email))),
        ) as string[];

        // Delete duplicates from the pending list
        for (const email of pendingUsersResponse) {
          if (currentMemberEmails.has(email)) {
            await deletePendingUserByEmail(email);
          }
        }

        setFetchedPendingUsers(filteredPendingUsers);
      } catch (error) {
        captureMessage('Error fetching pending users or removing duplicates');
        console.error('Error in fetchPendingUsersAndRemoveDuplicates:', error);
      }
    }
  }, [organizationData?.organization.organization_id]);

  const addEmail = (email: string) => {
    setEmailValue(prev => {
      return [...prev, email];
    });
  };

  const deleteEmail = (emailToDelete: string) => {
    setEmailValue(prev => {
      return prev.filter(email => email !== emailToDelete);
    });
  };

  const clearEmails = () => {
    setEmailValue([]);
  };

  const validateAndAddEmail = async (email: string) => {
    // Convert email to lowercase
    const lowercaseEmail = email.toLowerCase();

    if (!isEmailValid(lowercaseEmail)) {
      return 'Invalid email format. Please check.';
    }

    if (!isEmailUnique(lowercaseEmail, emailValue)) {
      return 'This email is already added. Please enter a new email.';
    }

    if (pendingUsers.includes(lowercaseEmail)) {
      return 'This email is already pending. Please enter a new email.';
    }

    // Fetch the user and check if the returned array is not empty
    let userFromDatabase;
    let attempts = 0;

    while (attempts < 3) {
      // Retry up to 3 times
      try {
        userFromDatabase = await getUserByEmail(lowercaseEmail);

        if (userFromDatabase && userFromDatabase.length > 0) {
          console.log(`Email already in use: ${lowercaseEmail}`);

          return `This email: ${lowercaseEmail} is already in use. Please enter a new email.`;
        }

        break; // If the call was successful, break the loop
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.status === 500) {
          attempts++;
          console.error(`Attempt ${attempts}: Server error when fetching user. Retrying...`);
        } else {
          console.error('An error occurred:', error);

          return 'An error occurred. Please try again.';
        }
      }
    }

    addEmail(lowercaseEmail);

    return null;
  };

  const submitEmails = async () => {
    setIsSubmitting(true);

    if (
      !organizationData?.organization ||
      !organizationData.organization.name ||
      !organizationData.organization.organization_id
    ) {
      console.error('Organization or organization name or organization id is not defined');
      setIsSubmitting(false);

      return;
    }

    try {
      for (const email of emailValue) {
        await sendInvitationEmail(email, organizationData.organization.name);
      }

      await addUsersFromInviteListToOrgById(organizationData.organization.organization_id, emailValue);

      // Refetch pending users after successful invitations
      await fetchPendingUsersAndRemoveDuplicates();
    } catch (error) {
      // Handle any errors during the invitation process
      console.error('Error sending invitations:', error);
    } finally {
      setIsSubmitting(false);
      setEmailValue([]);
      console.log('Finished submitting emails');
    }
  };

  const handleEnterSubmit = async (inputValue: string) => {
    if (isSubmitting) {
      captureMessage('Attempted to submit while already submitting');

      return 'Submission in progress';
    }

    // Convert inputValue to lowercase
    const trimmedEmail = inputValue.trim().toLowerCase();

    if (!trimmedEmail) {
      return 'Please enter an email address.';
    }

    const validationMessage = await validateAndAddEmail(trimmedEmail);

    if (validationMessage) {
      return validationMessage;
    }

    if (
      !organizationData?.organization ||
      !organizationData.organization.name ||
      !organizationData.organization.organization_id
    ) {
      console.error('Organization or organization name or organization id is not defined');
      setIsSubmitting(false);

      return 'Organization information is missing';
    }

    try {
      setIsSubmitting(true);
      await sendInvitationEmail(trimmedEmail, organizationData.organization.name);
      await addUsersFromInviteListToOrgById(organizationData.organization.organization_id, [trimmedEmail]);
      await fetchPendingUsersAndRemoveDuplicates();

      return null; // Indicate success
    } catch (error) {
      console.error('Error sending invitation:', error);
      captureMessage(`Error in sending invitation: ${error}`);

      return 'Failed to send invitation'; // Indicate failure
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFormSubmit = async (currentInputEmail: string) => {
    setIsSubmitting(true);

    // eslint-disable-next-line prefer-const
    let emailsToSubmit = [...emailValue];

    if (currentInputEmail.trim()) {
      const lowercaseEmail = currentInputEmail.toLowerCase();

      // Email Format Validation
      if (!isEmailValid(lowercaseEmail)) {
        // handle invalid email format (you can use a callback to send message to component)
        setIsSubmitting(false);

        return 'Invalid email format. Please check.';
      }

      // Database Check
      const userFromDatabase = await getUserByEmail(lowercaseEmail);

      if (userFromDatabase.length > 0) {
        // handle email already in use (you can use a callback to send message to component)
        setIsSubmitting(false);

        return `Email ${lowercaseEmail} is already in use. Please enter a new email.`;
      }

      emailsToSubmit.push(lowercaseEmail);
    }

    if (
      !organizationData?.organization ||
      !organizationData.organization.name ||
      !organizationData.organization.organization_id
    ) {
      console.error('Organization or organization name or organization id is not defined');
      setIsSubmitting(false);

      return 'Organization information is missing';
    }

    try {
      for (const email of emailsToSubmit) {
        await sendInvitationEmail(email, organizationData.organization.name);
      }

      await addUsersFromInviteListToOrgById(organizationData.organization.organization_id, emailsToSubmit);
      // other necessary steps
    } catch (error) {
      // error handling
      setIsSubmitting(false);

      return 'Failed to send invitations, please try again.';
    } finally {
      setIsSubmitting(false);
      clearEmails();
    }

    return 'Invitations sent successfully.';
  };

  return {
    emailValue,
    setEmailValue,
    isSubmitting,
    setIsSubmitting,
    handleFormSubmit,
    addEmail,
    clearEmails,
    deleteEmail,
    validateAndAddEmail,
    submitEmails,
    fetchPendingUsersAndRemoveDuplicates,
    fetchedPendingUsers,
    handleEnterSubmit,
    isEmailValid,
  };
};

export default useInvitationForm;
