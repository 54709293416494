import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

interface ApplicationInfoProps {
    text: string;
}

export default function ApplicationInfo({ text }: ApplicationInfoProps): JSX.Element {
    return (
        <div className="flex mx-auto">
            <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-3 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-1">
                <p className="text-sm text-blue-700">{text}</p>
            </div>
        </div>
    );
}
