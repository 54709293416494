import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import { InvitationsList, Spinner } from '..';
import useInvitationForm from '../../hooks/Invitations/useInvitationsForm';
import useMessage from '../../hooks/useMessage';
import { deletePendingUserByEmail, getOrganizationByUserId } from '../../services';
import { useUser } from '@auth0/nextjs-auth0/client';
import type { OrganizationData } from '../../interfaces/Organization/Organization';

export default function InvitationsForm() {
  const [inputValue, setInputValue] = useState('');
  const { user, isLoading } = useUser();
  const [isLoadingInvitations, setIsLoadingInvitations] = useState(false);
  const [organizationData, setOrganizationData] = useState<OrganizationData | null>(null);
  const pendingUsers: { name: string }[] = [];
  const {
    emailValue,
    isSubmitting,
    handleFormSubmit,
    clearEmails,
    deleteEmail,
    validateAndAddEmail,
    fetchPendingUsersAndRemoveDuplicates,
    fetchedPendingUsers,
    handleEnterSubmit,
  } = useInvitationForm(
    organizationData,
    pendingUsers.map(user => user.name),
  );
  const [message, showMessage] = useMessage();

  useEffect(() => {
    if (user && !isLoading) {
      getOrganizationByUserId(user.sub as string)
        .then((orgData: OrganizationData | null) => {
          // Check if orgData and orgData.organization are truthy before setting the state
          if (orgData?.organization) {
            setOrganizationData(orgData);
          } else {
            // Handle the case where no organization data is returned
            // This could involve setting organizationData to null or to a default state
            setOrganizationData(null);
            console.log('No organization data found');
          }
        })
        .catch(error => {
          console.error('Error fetching organization data:', error);
          // Optionally, handle errors by setting organizationData to null or a default error state
        });
    }
  }, [user, isLoading]);

  useEffect(() => {
    if (organizationData?.organization) {
      setIsLoadingInvitations(true); // Set loading to true before fetching
      console.log('Fetching pending users...');
      fetchPendingUsersAndRemoveDuplicates().finally(() => setIsLoadingInvitations(false)); // Set loading to false after fetching
    }
  }, [fetchPendingUsersAndRemoveDuplicates, organizationData?.organization]);

  const clearAll = () => {
    setInputValue('');
    clearEmails();
    showMessage('Cleared all emails');
  };

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const result = await handleEnterSubmit(inputValue);

      if (!result) {
        setInputValue('');
        clearEmails(); // Clear input only if submission was successful
        showMessage('Invitations sent successfully.');
      } else {
        showMessage(result); // Show error or status message
      }
    } else if (event.key === ' ') {
      event.preventDefault();

      const validationMessage = await validateAndAddEmail(inputValue.trim());

      if (validationMessage) {
        showMessage(validationMessage);
      } else {
        setInputValue('');
      }
    }
  };

  const handleDelete = (emailToDelete: string) => () => {
    deleteEmail(emailToDelete);
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const responseMessage = await handleFormSubmit(inputValue); // Capture the return value

    showMessage(responseMessage); // Use the captured value here
    setInputValue(''); // Reset the input field

    // Reload the pending users
    setIsLoadingInvitations(true); // Set loading to true before re-fetching
    fetchPendingUsersAndRemoveDuplicates().finally(() => setIsLoadingInvitations(false)); // Set loading to false after re-fetching
  };

  return (
    <div>
      <div className="py-8 pb-8">
        <form onSubmit={handleSubmit}>
          <div className="space-y-8 divide-y divide-gray-200 bg-sand">
            <div className="container bg-sand">
              <TextField
                label="Email Addresses"
                placeholder="example@gmail.com"
                value={inputValue}
                onChange={handleEmailInputChange}
                onKeyDown={handleKeyDown}
                fullWidth
                style={{ backgroundColor: 'white' }}
              />
              <div className="container">
                {emailValue.map(email => (
                  <Chip key={email} label={email} onDelete={handleDelete(email)} className="mx-1 mt-2 bg-white" />
                ))}
              </div>
            </div>
          </div>

          <div className="mt-2">
            <span className="text-sm font-medium text-red-500">{message}</span>
          </div>

          <p className="text-sm text-slate mt-2">To add multiple email addresses, press 'Space' after each one.</p>
          <p className="text-sm text-slate mt-1">
            After all emails have been added, press 'Enter' or Submit to send invitations.
          </p>

          <div className="mt-8">
            <div className="flex justify-start items-center">
              <button
                type="button"
                className="mr-4 inline-flex items-center rounded-full border border-slate bg-white px-8 py-3 text-[14px] text-[#2B3D45] shadow-sm hover:bg-sun focus:outline-none focus:ring-2 focus:ring-seaBlue focus:ring-offset-2"
                onClick={clearAll}
              >
                Clear All
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="rounded-full border border-transparent bg-seaBlue px-8 py-3 text-[14px] text-white shadow-sm hover:bg-sun hover:text-slate focus:outline-none focus:ring-2 focus:ring-seaBlue focus:ring-offset-2"
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        </form>
      </div>
      <h1 className="text-3xl font-bold text-slate">Pending Invitations</h1>
      {isLoadingInvitations ? (
        <Spinner />
      ) : fetchedPendingUsers?.length > 0 ? (
        <InvitationsList
          invitations={fetchedPendingUsers}
          onDelete={async email => {
            await deletePendingUserByEmail(email);
            // Re-fetch the pending users after deletion
            setIsLoadingInvitations(true); // Set loading to true before re-fetching
            fetchPendingUsersAndRemoveDuplicates().finally(() => setIsLoadingInvitations(false)); // Set loading to false after re-fetching
          }}
        />
      ) : (
        <p>No invitations sent.</p>
      )}
    </div>
  );
}
