import React from 'react';
import styles from '../../styles/Spinner.module.css';

const Spinner = () => {
  return (
    <span data-testid="spinner" className={styles.container}>
      <div className={styles.spin} />
    </span>
  );
};

export default Spinner;
