import { useCallback, useState } from 'react';
import { addOrgAppByUrl, deleteOrgAppByUrl, getOrgAppByUrl } from '../services';
import { captureException, captureMessage } from '@sentry/nextjs';
import { type UnifiedApplication, type UrlClient } from '../interfaces/Client/Client';

export const useCustomApplications = (organizationId: string) => {
  const [urlClients, setUrlClients] = useState<UnifiedApplication[]>([]); // Use UnifiedApplication
  const [isLoading, setIsLoading] = useState(false);

  const fetchUrlClients = useCallback(async () => {
    try {
      const orgAppsByUrl = await getOrgAppByUrl(organizationId);
      const urlClientNames = new Set();

      const urlClients = orgAppsByUrl.reduce((result: UnifiedApplication[], app: UrlClient) => {
        // Use UnifiedApplication
        if (!urlClientNames.has(app.name)) {
          urlClientNames.add(app.name);
          result.push({
            enabled: false,
            sso: false,
            uuid: '',
            categories: [],
            type: 'urlClient',
            ...app,
            callbacks: [],
          });
        }

        return result;
      }, []);

      setUrlClients(urlClients);
    } catch (error) {
      captureMessage('Failed to fetch URL clients in appCatalog.tsx');
      captureException(error);
      console.error('Failed to fetch URL clients:', error);
    }
  }, [organizationId]);

  const onAdd = async (app: { url: string; names: string }) => {
    setIsLoading(true);

    try {
      await addOrgAppByUrl(organizationId, [app.url], [app.names]);

      const newApp: UnifiedApplication = {
        url: app.url,
        name: app.names,
        type: 'urlClient',
        categories: [],
        uuid: '',
        enabled: false,
        sso: false,
        callbacks: [],
      };

      setUrlClients(prevClients => [...prevClients, newApp]);
    } catch (error) {
      captureMessage('Failed to add URL application');
      captureException(error);
      console.error('Error adding URL application for organization:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (url: string) => {
    setIsLoading(true);

    try {
      await deleteOrgAppByUrl(organizationId, url);

      setUrlClients(prevClients => prevClients.filter(client => client.url !== url));
    } catch (error) {
      captureMessage('Failed to delete URL application');
      captureException(error);
      console.error('Error deleting URL application for organization:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return { urlClients, fetchUrlClients, handleDelete, onAdd, isLoading };
};
