import { useCallback, useEffect, useState } from 'react';
import {
  createOrganizationApplication,
  deleteOrganizationApplication,
  deleteSSOClient,
  getTotalOrgClientsByOrgId,
  listCatalogApplications,
  listOrganizationApplication,
  updateOrganizationApplication,
} from '../services';
import {
  type CatalogApplication,
  type Client,
  type CreateOrganizationApplicationDto,
  type OrganizationApplication,
  type UnifiedApplication,
  type UpdateOrganizationApplicationDto,
} from '../interfaces/Client/Client';
import { captureException } from '@sentry/nextjs';
import { findUrlForClient } from '../utils/client-app-libs/findUrlForClient';
import { extractClientAppName } from '../utils';

export const useUnifiedApplications = (organizationId: string) => {
  const [applications, setApplications] = useState<UnifiedApplication[]>([]);
  const [catalogApplications, setCatalogApplications] = useState<UnifiedApplication[]>([]);
  const [selectedApplications, setSelectedApplications] = useState<UnifiedApplication[]>([]);
  const [displayedApplications, setDisplayedApplications] = useState<UnifiedApplication[]>([]);
  const [filteredCatalogApplications, setFilteredCatalogApplications] = useState<UnifiedApplication[]>([]);
  const [isHandlingApplicationAction, setIsHandlingApplicationAction] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchApplications = useCallback(async () => {
    setIsLoading(true);

    let catalogApps = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let orgApps: any[] = [];
    let ssoEnabledClients = [];

    try {
      catalogApps = await listCatalogApplications();
    } catch (err) {
      console.error('Failed to fetch catalog applications:', err);
      setError(err as Error);
    }

    try {
      orgApps = await listOrganizationApplication(organizationId);
    } catch (err) {
      console.error('Failed to fetch organization applications:', err);
      setError(err as Error);
    }

    try {
      ssoEnabledClients = await getTotalOrgClientsByOrgId(organizationId);

      // Ensure ssoEnabledClients is an array
      if (!Array.isArray(ssoEnabledClients)) {
        ssoEnabledClients = [];
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error('Failed to fetch SSO enabled clients:', err);

      if (err.status === 500) {
        // Check if the error status is 500
        ssoEnabledClients = [];
        setIsLoading(false);
      } else {
        setError(err as Error);
      }
    }

    const selectedApplications: UnifiedApplication[] = [];
    const catalogApplications: UnifiedApplication[] = [];

    // Filter and transform ssoEnabled clients
    const ssoEnabledApplications = ssoEnabledClients
      .filter((client: Client) => client.sso)
      .map((client: Client) => ({
        uuid: client.client_id,
        name: extractClientAppName(client.name),
        logo: client.logo_uri,
        customUrl: client.addons.samlp.recipient,
        enabled: true,
        type: 'ssoEnabled',
        signing_keys: client.signing_keys,
        callbacks: client.callbacks,
      }));

    // Add ssoEnabledApplications to selectedApplications
    selectedApplications.push(...ssoEnabledApplications);

    catalogApps.forEach((catalogApp: UnifiedApplication) => {
      const matchingOrgApps = orgApps.filter((orgApp: UnifiedApplication) => orgApp.applicationId === catalogApp.uuid);

      if (matchingOrgApps.some((app: UnifiedApplication) => app.enabled)) {
        const enabledApp = matchingOrgApps.find((app: UnifiedApplication) => app.enabled);

        selectedApplications.push({
          ...enabledApp,
          name: catalogApp.name,
          logo: catalogApp.logo,
          customUrl: enabledApp.customUrl && enabledApp.customUrl !== '' ? enabledApp.customUrl : catalogApp.baseUrl,
          type: 'organization',
          sso: catalogApp.sso,
          ssoUrlExample: catalogApp.ssoUrlExample,
        });
      } else {
        catalogApplications.push({
          ...catalogApp,
          enabled: false,
          type: 'catalog',
          logo: catalogApp.logo,
          baseUrl: catalogApp.baseUrl || findUrlForClient(catalogApp.name as string),
        });
      }
    });

    setCatalogApplications(catalogApplications);
    setSelectedApplications(selectedApplications);
    setApplications([...catalogApplications, ...selectedApplications]);
    setIsLoading(false);
    setTriggerFetch(false);
  }, [organizationId]);

  useEffect(() => {
    if (organizationId) {
      fetchApplications();
    }
  }, [organizationId, fetchApplications]);

  useEffect(() => {
    if (triggerFetch) {
      fetchApplications();
    }
  }, [triggerFetch, fetchApplications]);

  useEffect(() => {
    const newDisplayedApplications = (selectedApplications ?? [])
      .filter(application => {
        // If the application is SSO-enabled, always include it
        if (application.type === 'ssoEnabled') {
          return true;
        }

        // If the application is not SSO-enabled, only include it if it doesn't have an SSO counterpart
        return !selectedApplications?.some(
          otherApp => otherApp.logo === application.logo && otherApp.type === 'ssoEnabled',
        );
      })
      .map(application => {
        // Find the SSO counterpart for SSO-enabled applications
        const ssoCounterpart =
          application.type === 'ssoEnabled'
            ? selectedApplications?.find(
              otherApp => otherApp.logo === application.logo && otherApp.type === 'organization',
            )
            : null;

        return {
          ...application,
          // If there's an SSO counterpart, use its name
          name: ssoCounterpart?.name ?? application.name,
          hasSSOCounterpart: Boolean(ssoCounterpart),
        };
      });

    setDisplayedApplications(newDisplayedApplications);
  }, [selectedApplications]);

  useEffect(() => {
    const newDisplayedApplications = (selectedApplications ?? []);

    // Step 1: Create an array of selected application names
    const selectedApplicationNames = newDisplayedApplications.map(app => app.name);

    // Step 2: Filter the catalogApplications array
    const newFilteredCatalogApplications = catalogApplications.filter(
      app => !selectedApplicationNames.includes(app.name)
    );

    // Step 3: Set the filtered catalog applications array to the new state variable
    setFilteredCatalogApplications(newFilteredCatalogApplications);
  }, [selectedApplications, catalogApplications]);

  const createApplication = async (applicationData: CreateOrganizationApplicationDto): Promise<void> => {
    setIsLoading(true);

    if (!applicationData.organizationId) {
      throw new Error('Organization ID is missing');
    }

    try {
      await createOrganizationApplication(applicationData);
      await fetchApplications();
    } catch (err) {
      captureException(err);

      if (err instanceof Error) {
        setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateApplicationStatus = useCallback(
    async (applicationId: string, applicationData: CreateOrganizationApplicationDto): Promise<void> => {
      setIsLoading(true);

      if (!applicationData.organizationId) {
        throw new Error('Organization ID is missing');
      }

      // Optimistically update the state
      setSelectedApplications(prevSelectedApplications =>
        prevSelectedApplications.map(app => (app.uuid === applicationId ? { ...app, ...applicationData } : app)),
      );

      try {
        await updateOrganizationApplication(organizationId, applicationId, applicationData);
      } catch (err) {
        captureException(err);

        // If the update fails, revert the state back to its original form
        setSelectedApplications(prevSelectedApplications =>
          prevSelectedApplications.map(app =>
            app.uuid === applicationId ? { ...app, enabled: !applicationData.enabled } : app,
          ),
        );

        if (err instanceof Error) {
          setError(err);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId],
  );

  const updateApplication = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (applicationId: string, applicationData: CreateOrganizationApplicationDto): Promise<any> => {
      setIsHandlingApplicationAction(true);

      let response;

      if (!applicationData.organizationId) {
        throw new Error('Organization ID is missing');
      }

      // Optimistically update the state
      setSelectedApplications(prevSelectedApplications =>
        prevSelectedApplications.map(app => {
          if (app.uuid === applicationId) {
            try {
              return { ...app, ...applicationData };
            } catch (error) {
              console.error('Error during state update:', error);

              return app; // return the original app in case of error
            }
          } else {
            return app;
          }
        }),
      );

      try {
        response = await updateOrganizationApplication(organizationId, applicationId, applicationData);

        // Fetch applications after updating
        await fetchApplications();
      } catch (err) {
        captureException(err);

        // If the update fails, revert the state back to its original form
        setSelectedApplications(prevSelectedApplications =>
          prevSelectedApplications.map(app =>
            app.uuid === applicationId ? { ...app, customUrl: app.customUrl } : app,
          ),
        );

        if (err instanceof Error) {
          setError(err);
        }
      } finally {
        setIsHandlingApplicationAction(false);
      }

      return response;
    },
    [fetchApplications, organizationId],
  );

  async function updateUnifiedApplications(applicationId: string, updateData: UpdateOrganizationApplicationDto) {
    return updateApplication(applicationId, updateData);
  }

  const deleteApplication = useCallback(
    async (applicationId: string): Promise<void> => {
      setIsLoading(true);

      try {
        await deleteOrganizationApplication(organizationId, applicationId);
        await fetchApplications();
      } catch (err) {
        captureException(err);

        if (err instanceof Error) {
          setError(err);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId, fetchApplications],
  );

  const enableApplication = useCallback(
    async (application: UnifiedApplication): Promise<void> => {
      setIsLoading(true);

      try {
        const applicationData = {
          organizationId,
          applicationId: application.uuid,
          enabled: !application.enabled,
        };

        await updateOrganizationApplication(organizationId, application.uuid, applicationData);
        await fetchApplications();
      } catch (err) {
        captureException(err);

        if (err instanceof Error) {
          setError(err);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [organizationId, fetchApplications],
  );

  const handleEnableCatalogApp = async (application: CatalogApplication) => {
    setIsHandlingApplicationAction(true);

    try {
      // Fetch organization applications directly
      const orgApps = await listOrganizationApplication(organizationId);

      // Attempt to find a matching selected application by applicationId
      const matchingSelectedApp = orgApps.find(
        (app: OrganizationApplication) => app.applicationId === application.uuid,
      );

      if (matchingSelectedApp) {
        // If a matching selected application is found, update it
        const updateData = { organizationId, applicationId: application.uuid, enabled: true };

        await updateApplicationStatus(matchingSelectedApp.uuid, updateData);
      } else {
        // If no matching selected application is found, create a new one
        const createData = { organizationId, applicationId: application.uuid, enabled: true };

        await createApplication(createData);
      }

      // Refresh applications list
      await fetchApplications();
    } catch (error) {
      console.error('Error enabling catalog app:', error);
    } finally {
      setIsHandlingApplicationAction(false);
    }
  };

  const handleSelectedToggleStatus = async (application: UnifiedApplication) => {
    setIsHandlingApplicationAction(true);

    const appId = application.applicationId || application.uuid;

    if (appId) {
      try {
        if (application.type === 'organization' && organizationId) {
          const applicationData: UpdateOrganizationApplicationDto = {
            organizationId,
            applicationId: appId,
            enabled: !application.enabled,
          };

          await updateApplicationStatus(appId, applicationData);
        } else if (application.type === 'ssoEnabled') {
          await deleteSSOClient(appId);
        } else {
          console.error('Unknown application type:', application.type);
        }

        await fetchApplications();
      } catch (error) {
        console.error('Error updating application:', error);
      }
    } else {
      console.error('applicationId or uuid is not available:', application);
    }

    setIsHandlingApplicationAction(false);
  };

  const handleDeleteSSOClient = async (clientId: string) => {
    setIsHandlingApplicationAction(true);

    try {
      // Find the SSO client in selectedApplications
      const ssoClient = selectedApplications.find(app => app.client_id === clientId);

      if (ssoClient) {
        // Find the organization counterpart of the SSO client
        const organizationCounterpart = selectedApplications.find(
          app => app.logo === ssoClient.logo && app.type === 'organization'
        );

        // Remove the SSO client from selectedApplications
        const newSelectedApplications = selectedApplications.filter(
          app => app !== ssoClient
        );

        // If there's an organization counterpart, add it back to selectedApplications
        if (organizationCounterpart) {
          newSelectedApplications.push(organizationCounterpart);
        }

        setSelectedApplications(newSelectedApplications);
      }

      await deleteSSOClient(clientId);
      await fetchApplications();
    } catch (error) {
      console.error('Error deleting SSO client:', error);
    }

    setIsHandlingApplicationAction(false);
  };

  return {
    isLoading,
    error,
    applications,
    filteredCatalogApplications,
    selectedApplications,
    displayedApplications,
    createApplication,
    updateUnifiedApplications,
    deleteApplication,
    enableApplication,
    fetchApplications,
    handleSelectedToggleStatus,
    handleEnableCatalogApp,
    isHandlingApplicationAction,
    handleDeleteSSOClient,
    setTriggerFetch,
  };
};
