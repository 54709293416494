export const findUrlForClient = (clientName: string) => {
    if (typeof clientName !== 'string') {
        console.log('clientName is not a string:', clientName);

        return '';
    }

    const lowercaseClientName = clientName.trim().toLowerCase();

    switch (lowercaseClientName) {
        case 'adp':
            return 'https://www.adp.com/logins/adp-portal.aspx';
        case 'adp workforce':
        case 'adp-workforce':
        case 'adpworkforce':
        case 'adp workforce now':
        case 'adp-workforce-now':
        case 'adpworkforcenow':
            return 'https://workforcenow.adp.com/workforcenow/login.html';
        case 'aha':
        case 'aha!':
            return 'https://modsquad.aha.io';
        case 'asana':
            return 'https://app.asana.com';
        case 'bamboohr':
            return 'https://app.bamboohr.com/login/';
        case 'box':
            return 'https://account.box.com/login';
        case 'datadog':
        case 'data dog':
        case 'data-dog':
            return 'https://app.datadoghq.com/account/login';
        case 'docusign':
            return 'https://account.docusign.com/';
        case 'dropbox':
            return 'https://www.dropbox.com/login';
        case 'freshdesk':
        case 'fresh desk':
        case 'fresh-desk':
        case 'freshdesks':
            return 'https://support.freshdesk.com/en/support/login';
        case 'freshbooks':
        case 'freshbook':
            return 'https://auth.freshbooks.com/service/auth/integrations/sign_in';
        case 'greenhouse':
        case 'green house':
        case 'green-house':
            return 'https://app.greenhouse.io/users/sign_in';
        case 'github':
            return 'https://github.com/login';
        case 'gmail':
            return 'https://mail.google.com';
        case 'google':
        case 'google workspace':
        case 'google-workspace':
        case 'googleworkspace':
        case 'google suite':
            return 'https://accounts.google.com/';
        case 'gusto':
            return 'https://app.gusto.com/login';
        case 'hubspot':
            return 'https://app.hubspot.com/login';
        case 'lever':
            return 'https://hire.lever.co/signin';
        case 'monday':
            return 'https://auth.monday.com/oauth2/authorize?client_id=0c1c9b8b8b0b0b0b0b0b0b0b0b0b0b0b&response_type=code&redirect_uri=https://mod.monday.com';
        case 'mongo db':
        case 'mongo':
        case 'mongodb':
            return 'https://account.mongodb.com/account/login';
        case 'netsuite':
            return 'https://system.netsuite.com/pages/customerlogin.jsp';
        case 'office 365':
        case 'office365':
            return 'https://login.microsoftonline.com';
        case 'outlook':
            return 'https://outlook.office.com';
        case 'quickbooks':
            return 'https://accounts.intuit.com/app/sign-in';
        case 'rippling':
            return 'https://app.rippling.com/login';
        case 'sales force':
        case 'sales-force':
        case 'salesforce':
            return 'https://login.salesforce.com/';
        case 'servicenow':
            return 'https://www.servicenow.com/my-account/sign-in.html';
        case 'slack':
            return 'https://slack.com/signin';
        case 'splunk':
            return 'https://login.splunk.com/';
        case 'sumologic':
        case 'sumo logic':
            return 'https://service.sumologic.com/sumo/saml/login/1217122214';
        case 'zendesk':
            return 'https://www.zendesk.com/login/';
        case 'zoho':
            return 'https://accounts.zoho.com/signin';
        case 'zoom':
            return 'https://zoom.us/signin#/login';
        default:
            return ''; // Return an empty string or a default URL if the clientName is not found
    }
};
