import React, { type CSSProperties } from 'react';

interface AppByUrlProps {
    client: {
        url: string;
        names: string;
    };
    index: number;
    handleDelete: (url: string) => void;
}

const listItemStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    gap: '10px',
    background: '#ffffff',
    borderRadius: '10px',
    width: '220px',
    height: '60px',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    border: '1px solid #D9D9D9',
};

const AppByUrl: React.FC<AppByUrlProps> = ({
    client,
    handleDelete,
}) => {
    return (
        <div style={listItemStyle} className="flex-shrink-0">
            <div className="flex items-center">
                <a target="_blank" href={client.url}>
                    <span className="text-md font-bold text-center ml-4">{client.names}</span>
                </a>
            </div>
            <button
                onClick={() => handleDelete(client.url)}
                style={{
                    background: 'transparent',
                    border: 'none',
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>
            </button>
        </div>
    );
};

export default AppByUrl;
