import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import type { FC, HTMLProps } from 'react';

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  className?: HTMLProps<HTMLDivElement>['className'];
}

const SearchBar: FC<SearchBarProps> = ({ value, onChange, className }) => {
  return (
    <div className={`relative ${className}`}>
      <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-500" />
      <input
        type="text"
        placeholder="Search applications"
        className="pl-10 w-full text-black placeholder-gray-500 rounded-lg ring-1 ring-inset ring-gray-300 border-0"
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchBar;
