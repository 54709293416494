import React from 'react';
import Image from 'next/image';
import logo from '../../../assets/cubeless.png';
import styles from '../../../styles/Navbar.module.css';
import { Auth0LoginButton, Auth0SignUpButton } from '../..';

const LandingNavbar = () => {
  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <Image src={logo} alt="Logo" height="39" />
      </div>
      <div className={styles.navLinks}>
        <Auth0LoginButton />
        <br />
        <Auth0SignUpButton />
      </div>
    </div>
  );
};

export default LandingNavbar;
