
export type ValidateUrlResult = {
  isValid: boolean;
  url: string;
  web: boolean;
  local: boolean;
}

/**
 * Validates a URL and determines if it's a web URL or a local one.
 *
 * @param {string} test - The URL to be tested.
 * @returns {Promise<ValidateUrlResult>} An object containing the validation result and additional URL properties.
 */
export async function validateUrl(test: string): Promise<ValidateUrlResult> {
  try {
    const url = new URL(test);
    const web = url.protocol === 'http:' || url.protocol === 'https:';

    return { isValid: true, url: test, web, local: !web };
  } catch (err) {
    return { isValid: false, url: test, web: false, local: false };
  }
}

/**
 * Converts a URL to use the HTTPS protocol if valid and a web URL.
 *
 * @param {string} url - The URL to convert.
 * @returns {Promise<string>} The converted URL or the original URL if conversion is not applicable.
 */
export async function convertUrlToHttps(url: string): Promise<string> {
  const { isValid, url: validUrl, web } = await validateUrl(url);

  if (isValid && web) {
    return validUrl.replace('http:', 'https:');
  }

  return url;
}
